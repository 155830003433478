<template>
  <v-card elevation="2">
    <v-card-title class="justify-center">
      Historique des formations
    </v-card-title>
    <v-card-text>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
      <v-row>
        <v-col
          v-for="(reservation, index) in history.events"
          :key="index"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card elevation="1" max-height="400px" class="overflow-y-auto">
            <v-card-title class="justify-center">
              Formation: {{ reservation.name }} <br />le
              {{ dateFromat(reservation.start) }}
              <br />
              Nombre de participants:
              {{ reservation.nb_place_max - reservation.nb_place_rest }} /
              {{ reservation.nb_place_max }}
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-group v-if="reservation.formative" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Formateur</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item v-if="reservation.formative_id">
                    <v-list-item-content>
                      <p>Nom: {{ reservation.formative.last_name }}</p>
                      <p>Prénom: {{ reservation.formative.first_name }}</p>
                      <p>
                        Adresse mail:
                        {{ reservation.formative.contact.email }}
                      </p>
                      <p>
                        Téléphone: {{ reservation.formative.contact.phone }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-content>
                      <p>Nom: {{ reservation.formative_interne }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-group no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Entreprises</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(entreprise,
                    index_entreprise) in reservation.reservations"
                    :key="index_entreprise"
                    link
                  >
                    <v-list-item-content v-if="entreprise">
                      <p>Nom: {{ entreprise.contact.name }}</p>
                      <p>
                        Nombre de place réservées:
                        {{ entreprise.pivot.nb_place }}
                      </p>
                      <p>
                        Adresse mail:
                        {{ entreprise.email }}
                      </p>
                      <p>Téléphone: {{ entreprise.phone }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as dayjs from "dayjs";

export default {
  name: "OrgFormHistorique",
  mounted() {
    this.fechtHistory({ limit: 20, offset: 0 });
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    length() {
      return Math.ceil(this.history.size_max / 20);
    },
    ...mapGetters("organism", {
      history: "getHistory",
    }),
  },
  methods: {
    dateFromat(date) {
      return dayjs(date, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    refreshHistory() {
      this.fechtHistory({ limit: 20, offset: this.page - 1 });
    },
    ...mapActions("organism", ["fechtHistory"]),
  },
};
</script>
