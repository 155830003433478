const state = {
  domains: null,
  domainsListe: [],
  departements: null,
  announcement: null,
};

const getters = {
  getDomains: (state) => {
    return state.domains;
  },
  getDomainsListe: (state) => {
    return state.domainsListe;
  },
  getDepartements: (state) => {
    return state.departements;
  },
  getAnnouncement: (state) => {
    return state.announcement
  }
};

const mutations = {
  setDomain(state, data) {
    state.domains = data;
  },
  setDepartement(state, data) {
    state.departements = data;
  },
  setDomainList(state, data) {
    state.domainsListe = data;
  },
  setAnnouncement(state, data) {
    state.announcement = data;
  },
};

const actions = {
  fetchDomain({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("categories")
        .then(function(response) {
          commit("setDomain", response.data.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchDomaineList({ commit }, dom) {
    let domainList = [];
    dom.map((element) => {
      if (element.sub === true) {
        element.data.map((subDomain) => {
          subDomain.data.map((domaine) => {
            domainList.push({
              name: subDomain.title + " - " + domaine.label,
              id: domaine.id,
            });
          });
        });
      } else {
        element.data.map((domaine) => {
          domainList.push({
            name: element.title + " - " + domaine.label,
            id: domaine.id,
          });
        });
      }
    });
    commit("setDomainList", domainList);
  },
  fetchDepartement({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("department")
        .then(function(response) {
          commit("setDepartement", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchAnnouncement({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("home")
        .then(function(response) {
          commit("setAnnouncement", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
