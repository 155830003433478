const state = {
  myAnnouncementList: null,
  announcementList: null,
  announcement: null,
};

const getters = {
  getAnnouncementList: (state) => {
    return state.announcementList;
  },
  getAnnouncement: (state) => {
    return state.announcement;
  },
  getMyAnnouncementList: (state) => {
    return state.myAnnouncementList;
  },
};

const mutations = {
  setAnnouncementList(state, data) {
    state.announcementList = data;
  },
  setAnnouncement(state, data) {
    state.announcement = data;
  },
  setMyAnnouncementList(state, data) {
    state.myAnnouncementList = data;
  },
};

const actions = {
  fetchAnnouncement({ commit }, search) {
    console.log("ici c'est nancy");
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("annoucement/search?" + search)
        .then(function(response) {
          commit("setAnnouncementList", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchAnnouncementById({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("annoucements/" + id)
        .then(function(response) {
          commit("setAnnouncement", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchMyAnnouncementList({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("my_annoucement")
        .then(function(response) {
          commit("setMyAnnouncementList", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  addAnnoucement(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("annoucements", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteAnnoucement({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .delete("annoucements/" + id)
        .then((response) => {
          console.log("ok");
          dispatch("fetchMyAnnouncementList");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
