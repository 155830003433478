<template>
  <div class="card mb-0">
    <div class="card-body" style="padding: 40px;">
      <h3>Inscription Formateur</h3>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="lastname" label="Nom"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field v-model="firstname" label="Prénom"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="emailConf"
            :rules="[rules.required, rules.email]"
            label="Confirmer l'e-mail"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field v-model="phone" label="Téléphone"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!-- <v-text-field
            v-model="birth"
            label="Date de naissance"
          ></v-text-field> -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birth"
                label="Date de naissance"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birth"
              :active-picker.sync="activePicker"
              locale="fr-FR"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-2"
            label="Mot de passe"
            hint="Au moins 8 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Confirmer le mot de passe"
            hint="Au moins 8 characters"
            v-model="confPassword"
            class="input-group--focused"
            @click:append="show2 = !show2"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="departements"
            chips
            clearable
            deletable-chips
            multiple
            label="Selectionnez vos départements de mobilité"
            :items="departementsListe"
            item-text="name"
            item-value="id"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <input
            @change="handleImage"
            class="custom-input"
            type="file"
            accept="image/*"
          />
          <img style="" :src="image" alt="" />
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            v-model="description"
            label="Description"
            counter
            maxlength="500"
            full-width
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="12">
          <h4>Domaine de formation</h4>
          <competencieTable :competencies="competencie" />
        </v-col>
        <v-col cols="12" sm="12">
          <v-checkbox v-model="agree">
            <template v-slot:label>
              <div>
                En cochant cette case je reconnais avoir pris connaissance et
                accepte les
                <!--  <v-tooltip bottom>
                  <template v-slot:activator="{ on }"> -->
                <a target="_blank" href="/condition-general" @click.stop>
                  conditions Générales d'Utilisation du site
                </a>
                <!-- </template>
                  S'ouve dans un nouvel onglet
                </v-tooltip> -->
                ainsi que sa
                <!--  <v-tooltip bottom>
                  <template v-slot:activator="{ on }"> -->
                <a target="_blank" href="/politque-confidentialite" @click.stop>
                  Politique de Confidientialité.
                </a>
                <!-- </template>
                  S'ouve dans un nouvel onglet
                </v-tooltip> -->
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" sm="12">
          <v-btn
            type="button"
            depressed
            @click="sendRegister"
            dark
            style="background-color: #00897f;"
          >
            S'inscrire
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import competencieTable from "../Competencies/CompetencieTable";
//import competencieJson from "@/data/competencie.json";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FormateurRegisterForm",
  components: { competencieTable },
  created() {
    this.competencie = this.domains;
    this.departementsListe = this.dep;
  },
  data() {
    return {
      competencie: null,
      firstname: "",
      email: null,
      emailConf: null,
      phone: "",
      lastname: "",
      birth: "",
      description: "",
      image: "",
      password: "",
      confPassword: "",
      show1: false,
      show2: false,
      agree: false,
      activePicker: null,
      departements: [],
      departementsListe: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      date: null,
      menu: false,
    };
  },
  methods: {
    sendRegister() {
      if (this.agree === false) {
        this.setSnack({
          text:
            " Merci d'accepter les conditions générales d'utilisation et la politque de confidentialite",
          color: "red",
        });
        return;
      }
      var user = {
        identifiant: this.email,
        email: this.email,
        password: this.password,
        password_confirmation: this.confPassword,
        first_name: this.firstname,
        last_name: this.lastname,
        phone: this.phone,
        birthdate: this.birth,
        domain: this.competencie,
        desc: this.description,
        department: this.departements,
      };
      this.registerFormateur(user)
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.setSnack({
            text:
              " Merci de renseigner tous les champs du formulaire pour vous inscrire",
            color: "red",
          });
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.uploadImage();
      };
      reader.readAsDataURL(fileObject);
    },
    ...mapActions("user", ["registerFormateur"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
  computed: {
    ...mapGetters("meta_data", { domains: "getDomains" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
