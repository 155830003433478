<template>
  <div class="text-center">
    <v-snackbar
      v-model="data.active"
      :timeout="data.timeout"
      top
      right
      :color="data.color"
    >
      {{ data.text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="data.active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "snackbarManager",
  computed: {
    ...mapGetters("snackbar", { data: "getData" }),
  },
};
</script>
