const state = {
  competencies: null,
  agenda: null,
  organism: null,
  event: [],
  profil: null,
  user: null,
  reservRequest: null,
  calendar: [],
  formativeRequest: [],
  history: [],
};

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getProfil: (state) => {
    return state.profil;
  },
  getOrganism: (state) => {
    return state.organism;
  },
  getMyEvent: (state) => {
    return state.event;
  },
  getReservRequest: (state) => {
    return state.reservRequest;
  },
  getCalendar: (state) => {
    return state.calendar;
  },
  getFormativeRequest: (state) => {
    return state.formativeRequest;
  },
  getHistory: (state) => {
    return state.history;
  },
};

const mutations = {
  setOrganism(state, data) {
    state.organism = data;
  },
  setMyEvent(state, data) {
    state.event = data;
  },
  setCalendar(state, data) {
    state.calendar = data;
  },
  setProfil(state, data) {
    state.profil = data;
  },
  setUser(state, data) {
    state.user = data;
  },
  setReservRequest(state, data) {
    state.reservRequest = data;
  },
  setFormativeRequest(state, data) {
    state.formativeRequest = data;
  },
  patchReservation(state, data) {
    state.reservRequest = state.reservRequest.filter(
      (item) => item.event_id !== data.event_id && item.user_id !== data.user_id
    );
  },
  deleteMyCalendare(state, data) {
    state.reservRequest = state.calendar.filter(
      (item) => item.id !== data.event_id
    );
  },
  deleteEventFormative(state, index) {
    state.event.events[index].formative_id = null;
    state.event.events[index].formative = null;
  },
  deleteEventReservation(state, data) {
    state.event.events[data.index].reservations.splice(data.indexEntreprise, 1);
  },
  setHistory(state, data) {
    state.history = data;
  },
};

const actions = {
  fetchOrganismById({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/" + id + "/get")
        .then(function(response) {
          commit("setOrganism", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  addEvent({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("events", data)
        .then((response) => {
          dispatch("fetchMyEvent");
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  fetchMyEvent({ commit }, conf) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/myevent?limit=" + conf.limit + "&offset=" + conf.offset)
        .then(function(response) {
          commit("setMyEvent", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchMyCalendar({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/calendar")
        .then(function(response) {
          commit("setCalendar", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchReservRequest({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/reservation")
        .then(function(response) {
          commit("setReservRequest", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchFormativeRequest({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/formative")
        .then(function(response) {
          commit("setFormativeRequest", response.data.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fechtHistory({ commit }, conf) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("organism/myhisto?limit=" + conf.limit + "&offset=" + conf.offset)
        .then(function(response) {
          commit("setHistory", response.data.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  changeMyDomain(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("organism/mydomaine", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  changeMyInfo(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("organism/change", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  reservationAccept({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/reservation/accept", {
          user_id: data.user_id,
        })
        .then((response) => {
          commit("patchReservation", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  reservationRefuse({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/reservation/refuse", {
          user_id: data.user_id,
        })
        .then((response) => {
          commit("patchReservation", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/cancel")
        .then((response) => {
          commit("deleteMyCalendare", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEventFormative({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.id + "/formative/cancel")
        .then((response) => {
          commit("deleteEventFormative", data.index);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEventReservation({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/reservation/cancel", {
          user_id: data.user_id,
        })
        .then((response) => {
          commit("deleteEventReservation", data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
