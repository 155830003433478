<template>
  <v-app>
    <NavBar v-if="!mobileView" />
    <MobileNavBar v-else />
    <!-- Sizes your content based upon application components -->
    <v-main>
      <MySnackbar />
      <!-- Provides the application the proper gutter -->
      <div style="padding-top: 4rem">
        <!-- If using vue-router -->
        <router-view></router-view>
      </div>
    </v-main>

    <MyFooter />
  </v-app>
</template>

<script>
//import Home from "./views/Home";
import NavBar from "./components/Navigation/NavBar";
import MyFooter from "./components/Navigation/MyFooter";
import MySnackbar from "./components/snackbarManager";
import MobileNavBar from "./components/Navigation/MobileNavBar";

import { mapActions } from "vuex";

export default {
  name: "App",

  components: { NavBar, MyFooter, MySnackbar, MobileNavBar },

  data: () => ({
    mobileView: true,
  }),
  created() {
    this.tryAutoLogin();
    this.fetchDomain().then((response) => {
      this.fetchDomaineList(response.data.data);
    });
    this.fetchDepartement();
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1000;
    },
    ...mapActions("user", ["tryAutoLogin"]),
    ...mapActions("meta_data", [
      "fetchDomain",
      "fetchDepartement",
      "fetchDomaineList",
    ]),
  },
};
</script>

<style>
@import "./css/bootstrap.css";
@import "./style.css";
@import "./css/swiper.css";
@import "./css/dark.css";
@import "./css/font-icons.css";
@import "./css/animate.css";
@import "./css/magnific-popup.css";
@import "./css/custom.css";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700|Poppins:300,400,500,600,700|PT+Serif:400,400i&display=swap");

.main-color {
  color: #00897f;
}

.main-text {
  color: #00897f;
  font-size: 1.6rem;
}
.button-main {
  color: white;
  background-color: #00897f;
}
</style>
