<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title class="training-title-container">
        <span class="training-title"
          >{{ event.name || "Période de disponibilité" }} par
          {{ event.creator.contact.name }}</span
        >
      </v-card-title>
      <v-card-text style="padding: 20px">
        <div
          style="display: flex;
    justify-content: space-between; vertical-align: baseline;"
        >
          <v-row
            align="center"
            style="display: flex;
    justify-content: space-between;"
          >
            <v-col cols="12" sm="6">
              Notez {{ event.creator.contact.name }} :
            </v-col>
            <v-col cols="12" sm="6">
              <div style="display: inline-flex">
                ({{ event.creator.contact.coef }})
                <v-rating
                  v-model="rating1"
                  color="green"
                  background-color="green lighten-1"
                  half-increments
                  hover
                ></v-rating>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              Notez le formateur
            </v-col>
            <v-col cols="12" sm="6">
              <v-rating
                v-model="rating2"
                color="green"
                background-color="green lighten-1"
                half-increments
                hover
              ></v-rating>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="onClose">
          Annuler
        </v-btn>
        <v-btn color="green" class="main-color" text @click="onEventSave">
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "NotationModal",
  props: {
    opened: {
      required: false,
      default: false,
    },
    event: {
      required: true,
    },
    onSave: {
      required: true,
    },
    onClose: {
      required: true,
    },
  },
  mounted() {
    console.log(this.event);
  },
  data: () => ({ rating1: 0, rating2: 0 }),
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    dateFrom() {
      return dayjs(this.event.start, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
    dateTo() {
      return dayjs(this.event.end, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
  },
  methods: {
    onEventSave() {
      this.onSave(this.event);
    },
  },
};
</script>

<style>
.training-title-container {
  background-color: #00897f;
}

.training-title {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.training-detail-container {
  padding: 32px 16px 0 16px;
}

.training-date-wrapper {
  align-items: center;
}

.training-date-content {
  display: flex;
  flex-direction: column;
}
</style>
