const state = {
  competencies: null,
  agenda: null,
  formerList: null,
  former: null,
  event: null,
  availablity: [],
  history: [],
};

const getters = {
  getCompetencies: (state) => {
    return state.competencies;
  },
  getAgenda: (state) => {
    return state.agenda;
  },
  getFormer: (state) => {
    return state.former;
  },
  getFormerList: (state) => {
    return state.formerList;
  },
  getMyAvailablity: (state) => {
    return state.availablity;
  },
  getMyEvent: (state) => {
    return state.event;
  },
  getHistory: (state) => {
    return state.history;
  },
};

const mutations = {
  setFormer(state, data) {
    state.former = data;
  },
  setFormerList(state, data) {
    state.formerList = data;
  },
  setMyAvailablity(state, data) {
    state.availablity = data;
  },
  setMyEvent(state, data) {
    state.event = data;
  },
  setHistory(state, data) {
    state.history = data;
  },
};

const actions = {
  fetchFormerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("/formative/" + id + "/get")
        .then(function(response) {
          commit("setFormer", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchFormative({ commit }, search) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("formative/search?" + search)
        .then(function(response) {
          commit("setFormerList", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  addAvailablity({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("formative/availablity", data)
        .then((response) => {
          dispatch("fetchMyAvailablity");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAvailablity({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .delete("formative/availablity/" + id + "/delete")
        .then((response) => {
          dispatch("fetchMyAvailablity");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMyAvailablity({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("formative/myavailablity")
        .then(function(response) {
          commit("setMyAvailablity", response.data.data.availablity);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchMyEvent({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("formative/myevent")
        .then(function(response) {
          commit("setMyEvent", response.data.data.events);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fechtHistory({ commit }, conf) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("formative/myhisto?limit=" + conf.limit + "&offset=" + conf.offset)
        .then(function(response) {
          commit("setHistory", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  changeMyDomain(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("formative/mydomaine", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  changeMyInfo(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("formative/change", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  eventAccept({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + id + "/formative/accept")
        .then(function(response) {
          dispatch("fetchMyEvent");
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  eventReject({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + id + "/formative/refuse")
        .then(function(response) {
          dispatch("fetchMyEvent");
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  deleteEventFormative({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + id + "/formative/cancel")
        .then((response) => {
          dispatch("fetchMyEvent");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
