<template>
  <div class="register">
    <div id="wrapper" class="clearfix">
      <section id="content">
        <div class="content-wrap">
          <v-row align="center" justify="center">
            <v-col sm="6">
              <v-img src="../assets/LoginPage/illustration-connexion.svg" />
            </v-col>
            <v-col>
              <h2 style="color: #00897f">S'inscrire</h2>
              <v-select
                v-model="selectedProfile"
                :items="profiles"
                item-text="label"
                item-value="value"
                label="Je suis ..."
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <component :is="selectedProfile" />
      </section>
    </div>
  </div>
</template>

<script>
import formateur from "@/components/Registration/FormateurRegisterForm";
import OrganismeFormationRegisterForm from "@/components/Registration/OrganismeFormationRegisterForm";
import entreprise from "@/components/Registration/EntrepriseRegisterForm";

export default {
  name: "Register",
  components: { formateur, OrganismeFormationRegisterForm, entreprise },
  data() {
    return {
      selectedProfile: "",
      profiles: [
        { label: "Un formateur", value: "formateur" },
        { label: "Une entreprise", value: "entreprise" },
        {
          label: "Un organisme de formation",
          value: "OrganismeFormationRegisterForm",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.register {
  padding-top: 50px;
}
</style>
