<template>
  <div>
    <v-container>
      <h4>Changer votre adresse email</h4>
      <EmailChange :email="emailChange" />
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <div class="col-12 form-group">
        <h4>Changer votre mot de passe</h4>
        <PasswordChange />
      </div>
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <h4>Changer votre profil</h4>
      <v-row>
        <v-col sm="12"
          ><v-text-field v-model="companyName" label="Nom"></v-text-field
        ></v-col>
        <v-col sm="6" md="4">
          <v-text-field v-model="phone" label="Téléphone"></v-text-field
        ></v-col>
        <v-col sm="6" md="4">
          <v-text-field v-model="website" label="Site internet"></v-text-field
        ></v-col>
        <v-col sm="6" md="4">
          <v-text-field v-model="adress" label="Adresse Postale"></v-text-field
        ></v-col>
        <v-col sm="12"
          ><v-textarea
            v-model="description"
            label="Description"
            counter
            maxlength="800"
            full-width
          ></v-textarea
        ></v-col>
        <v-col sm="8"
          ><v-checkbox v-model="qualiopi" label="Certifié Qualiopi">
          </v-checkbox
        ></v-col>
        <v-col sm="4">
          <v-btn type="button" depressed dark color="#00897f" @click="saveInfo">
            Valider
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>
    <v-container>
      <div style="padding-bottom: 10px">
        <h4 style="display: inline;">Domaine de formation</h4>
        <v-btn
          depressed
          dark
          color="#00897f"
          style="float: right;"
          @click="saveDomain"
        >
          Valider
        </v-btn>
      </div>
      <CompetenciesTable :competencies="competencies" type="1" />
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CompetenciesTable from "../../Competencies/CompetencieTable";
import EmailChange from "../common/EmailChange";
import PasswordChange from "../common/PasswordChange";

export default {
  name: "OrgFormInformation",
  components: { CompetenciesTable, EmailChange, PasswordChange },
  created() {
    this.competencies = this.profil.domain;
    this.emailChange = this.user.email;
    this.phone = this.user.phone;
    this.website = this.profil.site;
    this.adress = this.profil.address;
    this.companyName = this.profil.name;
    this.description = this.profil.desc;
    this.qualiopi = this.profil.qualiopi === 1 ? true : false;
  },
  data() {
    return {
      companyName: "",
      emailChange: "",
      phone: "",
      website: "",
      adress: "",
      description: "",
      image: "",
      competencies: null,
      password: "",
      confPassword: "",
      qualiopi: false,
      show1: false,
      show2: false,
      agree: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUser", profil: "getProfil" }),
  },
  methods: {
    changeMail() {
      /*let body = {
        emailChange: this.debutHeure,
        end: this.finHeure,
      };
      this.addEvent(body).then(response => {
        this.setSnack(response.message);        
      }
      ).catch(error => {
          this.setSnack(error.message);
        }
      );*/
    },
    saveDomain() {
      let body = {
        domain: this.competencies,
      };
      this.changeMyDomain(body)
        .then((response) => {
          this.getUserById();
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error, color: "red" });
        });
    },
    saveInfo() {
      let body = {
        name: this.companyName,
        phone: this.phone,
        address: this.adress,
        desc: this.description,
        site: this.website,
        qualiopi: this.qualiopi,
      };
      this.changeMyInfo(body)
        .then((response) => {
          this.getUserById();
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error.message, color: "red" });
        });
    },
    ...mapActions("organism", ["changeMyDomain", "changeMyInfo"]),
    ...mapActions("user", ["getUserById"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>
