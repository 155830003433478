<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      style="padding: 5px; justify-content: center; "
      multiple
      focusable
      popout
    >
      <v-expansion-panel key="1">
        <v-expansion-panel-header class="head">
          Ajouter une disponiblité
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form style="padding-top: 20px">
            <v-row>
              <v-col cols="12" sm="12" md="4"
                >Date et heure du début de la disponbilitée :</v-col
              >
              <v-col cols="12" sm="12" md="8">
                <VueCtkDateTimePicker
                  id="HeureDebut"
                  v-model="debutHeure"
                  locale="fr"
                  format="YYYY-MM-DD HH:mm:ss"
                  value="2018-04-07 20:26"
                  title="Début de la disponbilité"
                  description="Début de la disponbilité"
                />
              </v-col>
              <v-col cols="12" sm="12" md="4"
                >Date et heure de la fin de la disponbilitée :</v-col
              >
              <v-col cols="12" sm="12" md="8">
                <VueCtkDateTimePicker
                  id="HeureFin"
                  v-model="finHeure"
                  locale="fr"
                  format="YYYY-MM-DD HH:mm:ss"
                  value="2018-04-07 20:26"
                  title="Fin de la disponbilité"
                  description="Fin de la disponbilité"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <v-btn color="#00897F" text elevation="1" @click="saveTime"
                  >Sauvegarder</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="2">
        <v-expansion-panel-header class="head">
          Agenda
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchDetailAgenda
            :events="slots"
            :onEventClicked="handleEventClicked"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="3">
        <v-expansion-panel-header class="head">
          Mes demandes de réservation
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-col
              v-for="myEvent in eventWaiting"
              :key="myEvent.id"
              cols="12"
              md="4"
              sm="12"
            >
              <v-card elevation="1" max-height="400px" class="overflow-y-auto">
                <v-card-title class="text"> {{ myEvent.name }}</v-card-title>

                <v-card-text>
                  <p>
                    <b> Domaine :</b>
                    {{ domainsListe[myEvent.domain_id - 1].name }}
                  </p>
                  <p><b> Description :</b> {{ myEvent.desc }}</p>
                  <p>
                    Du {{ dateFrom(myEvent.start) }} Au
                    {{ dateTo(myEvent.end) }}
                  </p>
                  <p>
                    <b> Adresse :</b> {{ myEvent.address }}, {{ myEvent.city }}
                  </p>
                  <p>
                    <b>Statut :</b>
                    {{ infoStatut(myEvent.active, myEvent.formative_id) }}
                  </p>
                </v-card-text>

                <v-card-actions>
                  <v-btn color="green" text @click="Accept(myEvent.id)">
                    Accepter
                  </v-btn>
                  <v-btn color="red" text @click="Reject(myEvent.id)">
                    Refuser
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="4">
        <v-expansion-panel-header class="head">
          Mes formation
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-col
              v-for="myEvent in eventAccepted"
              :key="myEvent.id"
              cols="12"
              md="4"
              sm="12"
            >
              <v-card elevation="1" max-height="500px" class="overflow-y-auto">
                <v-card-title class="text">
                  {{ myEvent.name }} par
                  {{ myEvent.creator.contact.name }}</v-card-title
                >

                <v-card-text>
                  <p>
                    <b> Contact :</b>
                    {{ myEvent.creator.email }} | {{ myEvent.creator.phone }}
                  </p>
                  <p>
                    <b> Adresse :</b> {{ myEvent.address }}, {{ myEvent.city }}
                  </p>
                  <p>
                    <b> Domaine :</b>
                    {{ domainsListe[myEvent.domain_id - 1].name }}
                  </p>
                  <p><b> Description :</b> {{ myEvent.desc }}</p>
                  <p>
                    Du {{ dateFrom(myEvent.start) }} Au
                    {{ dateTo(myEvent.end) }}
                  </p>
                  <p>
                    <b>Statut :</b>
                    {{ infoStatut(myEvent.active, myEvent.formative_id) }}
                  </p>
                  <p>
                    <v-icon>mdi-account</v-icon> Place maximum:
                    {{ myEvent.nb_place_max }}
                    | Place minimum:
                    {{ myEvent.nb_place_min }} | Place restante:
                    {{ myEvent.nb_place_rest }}
                  </p>
                </v-card-text>
                <v-btn color="red" text @click="deleteEvent(myEvent.id)">
                  Annuler
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <AvailabiltyConfModal
      :opened="dialogOpen"
      :event="selectedEvent"
      :onClose="handleModalClosed"
      :onDelete="handleEventDelete"
    />
  </div>
</template>

<script>
//Agenda
import SearchDetailAgenda from "@/views/Search/Detail/SearchDetailAgenda";
import { mapGetters, mapActions } from "vuex";
import AvailabiltyConfModal from "./AvailabiltyConfModal.vue";
import * as dayjs from "dayjs";

export default {
  name: "FormateurCalendar",
  components: { SearchDetailAgenda, AvailabiltyConfModal },
  data: () => ({
    add: false,
    dialogOpen: false,
    finHeure: null,
    debutHeure: null,
    panel: [],
    selectedEvent: {},
  }),
  mounted() {
    this.fetchMyAvailablity();
    this.fetchMyEvent();
  },
  computed: {
    ...mapGetters("former", { slot: "getMyAvailablity" }),
    ...mapGetters("former", { myEvents: "getMyEvent" }),
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    slots() {
      let result = [];
      if (this.myEvents && this.slot) {
        result = this.myEvents.filter((x) => x.active === 1);
        return result.concat(this.slot);
      } else if (this.slot) {
        return this.slot;
      } else if (this.myEvents) {
        return this.myEvents.filter((x) => x.active === 1);
      } else {
        return [];
      }
    },
    eventWaiting() {
      if (this.myEvents) {
        return this.myEvents.filter((x) => x.active === 0);
      }
      return null;
    },
    eventAccepted() {
      if (this.myEvents) {
        return this.myEvents.filter((x) => x.active === 1);
      }
      return null;
    },
  },
  methods: {
    saveTime() {
      let body = {
        start: this.debutHeure,
        end: this.finHeure,
      };
      this.addAvailablity(body)
        .then((response) => {
          this.setSnack({ text: response.message });
          this.panel = this.panel.filter((item) => item !== 0);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.setSnack({
              text: error.response.data.message,
              color: "red",
            });
          } else {
            this.setSnack({
              text: "Vérifier la date de début et de fin",
              color: "red",
            });
          }
        });
    },
    handleEventClicked(event) {
      this.selectedEvent = event;
      this.dialogOpen = true;
    },
    handleModalClosed() {
      this.selectedEvent = {};
      this.dialogOpen = false;
    },
    handleEventDelete() {
      this.dialogOpen = false;
      if (this.selectedEvent.active) {
        this.deleteEvent(this.selectedEvent.id);
      } else {
        this.deleteAvailablity(this.selectedEvent.id);
      }
    },
    deleteEvent(id) {
      this.deleteEventFormative(id);
    },
    infoStatut(active, formative_id) {
      if (active === 1) {
        return "Formation Plannifiée";
      } else if (active === 0 && formative_id !== null) {
        return "En attente de confirmation du formateur";
      }
    },
    Accept(id) {
      this.eventAccept(id);
    },
    Reject(id) {
      this.eventReject(id);
    },
    dateFrom(start) {
      return dayjs(start, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    dateTo(end) {
      return dayjs(end, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    ...mapActions("former", [
      "addAvailablity",
      "fetchMyAvailablity",
      "fetchMyEvent",
      "deleteAvailablity",
      "eventAccept",
      "eventReject",
      "deleteEventFormative",
    ]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: #00897f;
  color: white;
}
</style>
