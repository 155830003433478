<template>
  <v-card elevation="2">
    <v-card-title class="justify-center">
      Historique des formations
    </v-card-title>
    <v-card-text>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
      <v-row>
        <v-col
          v-for="(reservation, index) in history.events"
          :key="index"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card elevation="3" max-height="400px" class="overflow-y-auto">
            <v-card-title class="justify-center">
              Formation: {{ reservation.name }} <br />le
              {{ dateFromat(reservation.start) }}
              <br />
              Par {{ reservation.creator.contact.name }}
            </v-card-title>
            <v-card-actions class="justify-center">
              <v-btn dark text color="#00897f" @click="openNotaionModal(index)"
                >Notez la formation</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
    </v-card-text>
    <NotationModal
      v-if="notationModal"
      :opened="notationModal"
      :event="selectedEvent"
      :onClose="handleModalClosed"
      :onSave="saveRating"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as dayjs from "dayjs";
import NotationModal from "./NotationModal";

export default {
  name: "EntrepriseHistorique",
  components: { NotationModal },
  mounted() {
    this.fechtHistory({ limit: 20, offset: 0 });
  },
  data: () => ({
    page: 1,
    rating: 0,
    selectedEvent: {},
    notationModal: false,
  }),
  computed: {
    length() {
      return Math.ceil(this.history.size_max / 20);
    },
    ...mapGetters("enterprise", {
      history: "getHistory",
    }),
  },
  methods: {
    dateFromat(date) {
      return dayjs(date, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    refreshHistory() {
      this.fechtHistory({ limit: 20, offset: this.page - 1 });
    },
    openNotaionModal(index) {
      this.selectedEvent = this.history.events[index];
      this.setSnack({
        text: "La notation arrive prochainement !",
        color: "green",
      });
      /*this.notationModal = true; */
    },
    handleModalClosed() {
      this.selectedEvent = {};
      this.notationModal = false;
    },
    saveRating() {
      this.selectedEvent = {};
      this.notationModal = false;
    },
    ...mapActions("enterprise", ["fechtHistory"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>

<style lang="scss" scoped>
.notation {
  display: inline-flex;
}
</style>
