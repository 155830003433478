import axios from "axios";
import store from "@/store/index.js";
let VueAxiosPlugin = {};

VueAxiosPlugin.install = (Vue, options) => {
  const defaultOptions = {
    // request interceptor handler
    reqHandleFunc: (config) => {
      //var c = _.clone(config);

      var user_token = store.state.user.access_token;

      if (user_token) {
        config.headers["Authorization"] = "Bearer " + user_token;
      }

      // config.headers["Access-Control-Allow-Origin"] = "*";
      // config.headers["Access-Control-Allow-Methods"] =
      // 	"DELETE, POST, GET, OPTIONS";
      // config.headers["Access-Control-Allow-Headers"] =
      // 	"Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";

      return config;
    },
    reqErrorFunc: (error) => Promise.reject(error),
    // response interceptor handler
    resHandleFunc: (response) => {
      if (
        response.status !== 200 &&
        response.status !== 201 &&
        response.status !== 202
      ) {
        return Promise.reject(response);
      }

      return response;
    },
    resErrorFunc: (error) => Promise.reject(error),
  };

  const initOptions = {
    ...defaultOptions,
    ...options,
  };

  const service = axios.create(initOptions);

  // Add a request interceptor
  service.interceptors.request.use(
    (config) => initOptions.reqHandleFunc(config),
    (error) => initOptions.reqErrorFunc(error)
  );
  // Add a response interceptor
  service.interceptors.response.use(
    (response) => initOptions.resHandleFunc(response),
    (error) => initOptions.resErrorFunc(error)
  );

  Vue.prototype.$axios = service;
  Vue.prototype.$http = {
    get: (url, data, options) => {
      let axiosOpt = {
        ...options,
        ...{
          method: "get",
          url: url,
          params: data,
        },
      };
      return service(axiosOpt);
    },
    post: (url, data, options) => {
      let axiosOpt = {
        ...options,
        ...{
          method: "post",
          url: url,
          data: data,
        },
      };
      return service(axiosOpt);
    },
  };
};

// Auto-install
let GlobalVue = null;
if (typeof window !== "undefined") {
  GlobalVue = window.Vue;
} else if (typeof global !== "undefined") {
  GlobalVue = global.Vue;
}
if (GlobalVue) {
  GlobalVue.use(VueAxiosPlugin);
}

export default VueAxiosPlugin;
