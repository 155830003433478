<template>
  <div>
    <div class="wrapper">
      <div class="content-wrap">
        <div
          v-if="user.id"
          style="padding: 20px; padding-top: 40px;"
          :key="update"
        >
          <div v-if="!user.email_verified_at">
            <EmailConfirmation :refresh="refresh" />
          </div>
          <div v-else>
            <h2 style="text-align: center; color: #00897F">
              Paramètre du compte
            </h2>
            <h4 style="text-align: center;">
              Changer votre profil et paramètres de compte
            </h4>

            <Settings />
          </div>
        </div>

        <div
          v-else
          style="padding: 20px; padding-top: 40px; align-item: center"
        >
          <v-row>
            <v-col cols="12" md="4" sm="12"> </v-col>
            <v-col style="padding-top: 2rem" cols="12" md="4" sm="12">
              <v-btn
                block
                color="#00897f"
                dark
                :to="{
                  name: 'Login',
                }"
              >
                Merci de vous reconnecter
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="12"> </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from "@/components/AccountSettings/UserSettings";
import EmailConfirmation from "@/components/AccountSettings/EmailConfirmation";

import { mapGetters } from "vuex";

export default {
  name: "AccountSettings",
  components: { Settings, EmailConfirmation },
  data() {
    return { update: 0, keys: -1 };
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    refresh() {
      this.update++;
    },
  },
  computed: {
    ...mapGetters("user", { user: "getUser" }),
  },
};
</script>
