<template>
  <div class="clearfix">
    <div class="wrapper">
      <div class="content-wrap">
        <div class="container form-wrapping">
          <h1
            dir="ltr"
            style="line-height: 1.2; text-align: center; background-color: rgb(68, 174, 149); margin-top: 0pt; margin-bottom: 0pt; padding: 0pt 0pt 10pt;"
          >
            <span
              style="font-size:48pt;font-family:'Helvetica Neue',sans-serif;color:#ffffff;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Catalogue des formations</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >SECOURS</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;SST Sauvetage Secourisme du Travail</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Dans son entreprise, le&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >sauveteur secouriste du travail</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;intervient dans un cadre juridique fix&eacute; par le Code
              du travail et le Code p&eacute;nal.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >En cas d&apos;accident, son action s&apos;articule avec celle des
              secours sp&eacute;cialis&eacute;s.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Il contribue &agrave; la pr&eacute;vention des risques
              professionnels dans son entreprise par sa connaissance des
              m&eacute;canismes de l&apos;accident et son aptitude &agrave;
              rep&eacute;rer et signaler les situations dangereuses.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;PSC1</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le certificat pr&eacute;vention et secours civiques de niveau 1,
              en abr&eacute;g&eacute; PSC1, est la formation de base aux
              premiers secours en France.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;L&rsquo;AFGSU</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >L&rsquo;AFGSU est destin&eacute;e aux personnels travaillant dans
              les &eacute;tablissements sanitaires et m&eacute;dicaux-sociaux ou
              aux professionnels de sant&eacute; selon le niveau.</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >AFGSU Niveau 1&nbsp;: Personnels travaillant dans les
                  &eacute;tablissements sanitaires et
                  m&eacute;dicaux-sociaux</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >AFGSU Niveau 2&nbsp;: Professionnels de sant&eacute;</span
                >
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Les gestes qui sauvent (GQS)</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Sensibilisation du public aux gestes de premiers secours.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Les formateurs</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Formateur SST&nbsp;: formation destin&eacute;e &agrave;
                  former des formateurs pour enseigner le sauvetage secourisme
                  du travail.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Formateur PAE3&nbsp;: formation destin&eacute;e &agrave;
                  former des formateurs pour enseigner le PSC1.</span
                >
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;ASD Aide et soin &agrave; domicile</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >AP = Animateur Pr&eacute;vention</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >APS = Acteur Pr&eacute;vention Secours</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Les comp&eacute;tences d&eacute;velopp&eacute;es en formation
              permettent &agrave; l&rsquo;intervenant &agrave; domicile de
              contribuer &agrave; la r&eacute;duction, voire &agrave; la
              suppression des risques professionnels auxquels il est
              expos&eacute;. Acteur de la pr&eacute;vention, il propose des
              am&eacute;liorations de ses situations de travail, de la
              qualit&eacute; de la prise en charge et de la
              s&eacute;curit&eacute; des personnes accompagn&eacute;es.
              L&rsquo;APS ASD a les comp&eacute;tences pour porter secours comme
              le sauveteur secouriste du travail.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <br />
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Pr&eacute;vention li&eacute;e &agrave; l&rsquo;activit&eacute;
              physique</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;PRAP Pr&eacute;vention des risques li&eacute;s &agrave;
              l&apos;activit&eacute; physique</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Les manutentions manuelles, le port de charges, les gestes
              r&eacute;p&eacute;titifs, les postures de travail
              prolong&eacute;es, les vibrations, peuvent nuire &agrave; la
              sant&eacute; ou entra&icirc;ner des efforts inutiles ou
              excessifs.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation PRAP permet aux salari&eacute;s de prendre en charge
              leur propre pr&eacute;vention et de participer aux actions de
              pr&eacute;vention des risques professionnels dans leur
              entreprise.&nbsp;</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >IBC</span
                ><span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;= Industrie, BTP, activit&eacute; de bureau et
                  commerce</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >2S</span
                ><span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;= Sanitaire et m&eacute;dico-social</span
                >
              </p>
            </li>
          </ul>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Les formateurs</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Formateur PRAP IBC&nbsp;: formation destin&eacute;e &agrave;
                  former des formateurs IBC</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Formateur PRAP 2S&nbsp;: formation destin&eacute;e &agrave;
                  former des formateurs 2S</span
                >
              </p>
            </li>
          </ul>
          <p style="text-align: left;"><br /></p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Gestes et postures</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Diminuer les risques d&apos;accidents dorsolombaires sur les
              lieux de travail en appliquant les principes de
              s&eacute;curit&eacute; physique et d&apos;&eacute;conomie de
              l&apos;effort.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;TMS Troubles musculosquelettiques</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation permet d&rsquo;accompagner le chef
              d&rsquo;entreprise dans l&rsquo;identification de la
              probl&eacute;matique TMS dans son entreprise, avec la mise en
              place d&rsquo;un programme de pr&eacute;vention et de
              d&eacute;velopper les comp&eacute;tences d&rsquo;une &laquo;
              personne ressource &raquo; charg&eacute;e d&rsquo;animer le projet
              dans l&rsquo;entreprise et de mettre en &oelig;uvre la
              d&eacute;marche de pr&eacute;vention. &nbsp;</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >PREVENTION</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;SMS</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >AP= Animateur pr&eacute;vention</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Secteur m&eacute;dico-social. Le secteur de
              l&rsquo;h&eacute;bergement et de l&rsquo;accueil des personnes
              &acirc;g&eacute;es, qui repr&eacute;sente pr&egrave;s de 200 000
              salari&eacute;s, a vu le nombre d&rsquo;accidents du travail et de
              maladies professionnelles croitre fortement sur la p&eacute;riode
              2008-2012.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /><br
            /></span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;EVRP/SDC Evaluation des risques professionnels et missions
              du salari&eacute; d&eacute;sign&eacute; comp&eacute;tent en
              sant&eacute; et s&eacute;curit&eacute; au travail</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Pour &ecirc;tre efficace, la d&eacute;marche de pr&eacute;vention
              des risques professionnels en entreprise doit &ecirc;tre
              structur&eacute;e et s&rsquo;appuyer sur des personnes ressources
              ayant les comp&eacute;tences n&eacute;cessaires pour y
              contribuer.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;CSE&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation destin&eacute;e aux membres de la
              d&eacute;l&eacute;gation du personnel du&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >comit&eacute; social et &eacute;conomique</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;CSE n&eacute;cessaire &agrave; l&rsquo;exercice de leurs
              missions en mati&egrave;re de sant&eacute;, de
              s&eacute;curit&eacute; et de conditions de travail.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;CSSCT</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation destin&eacute;e aux membres de la commission de
              sant&eacute;, s&eacute;curit&eacute; et conditions de travail
              (CSSCT) qui doit &ecirc;tre instaur&eacute;e au sein du
              comit&eacute; social et &eacute;conomique (CSE).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <br />
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >AMIANTE&nbsp;</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;La formation Amiante sous-section 3</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;constitue une obligation r&eacute;glementaire &agrave; la
              charge de l&rsquo;employeur dont les activit&eacute;s sont
              d&eacute;finies &agrave; l&rsquo;article R.4412-94-1. Les
              modalit&eacute;s de la formation des travailleurs &agrave; la
              pr&eacute;vention des risques li&eacute;s &agrave; l&rsquo;amiante
              sous-section 3 sont pr&eacute;cis&eacute;es dans
              l&rsquo;arr&ecirc;t&eacute; du 23/02/12.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La sous-section 3 s&apos;applique aux activit&eacute;s de
              confinement et de retrait de mat&eacute;riaux contenant de
              l&apos;amiante.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;3 publics sont concern&eacute;s : personnel
              d&rsquo;encadrement technique/personnel d&rsquo;encadrement de
              chantier/op&eacute;rateur de chantier.&nbsp;</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;La formation amiante sous-section 4</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;constitue une obligation r&eacute;glementaire &agrave; la
              charge de l&rsquo;employeur. Cette obligation, port&eacute;e par
              l&rsquo;arr&ecirc;t&eacute; du 23 f&eacute;vrier 2012, concerne
              les salari&eacute;s des entreprises et les travailleurs
              ind&eacute;pendants, amen&eacute;s &agrave; r&eacute;aliser des
              activit&eacute;s relevant de la sous-section 4 (article
              R.4412-94-2).&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le dispositif d&rsquo;habilitation amiante sous-section 4,
              pilot&eacute; par l&rsquo;INRS, a pour objectif de
              compl&eacute;ter les dispositions de l&rsquo;arr&ecirc;t&eacute;
              du 23/02/2012 concernant la formation des travailleurs &agrave; la
              pr&eacute;vention du risque amiante.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La sous-section 4 concerne les activit&eacute;s comprenant des
              interventions sur des mat&eacute;riaux ou appareils susceptibles
              de lib&eacute;rer des fibres d&apos;amiante.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >4 cat&eacute;gories de publics : personnel d&rsquo;encadrement
              technique/personnel d&rsquo;encadrement de
              chantier/op&eacute;rateur de chantier/travailleur cumulant toute
              ou partie de ces fonctions.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <br />
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Travail en hauteur&nbsp;:</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le code du travail (article R. 4323-69) pr&eacute;cise que
              &laquo; Les &eacute;chafaudages ne peuvent &ecirc;tre
              mont&eacute;s, d&eacute;mont&eacute;s ou sensiblement
              modifi&eacute;s que sous la direction d&apos;une personne
              comp&eacute;tente et par des travailleurs qui ont re&ccedil;u une
              formation ad&eacute;quate et sp&eacute;cifique aux
              op&eacute;rations envisag&eacute;es &raquo;.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le dispositif de formation &laquo;&eacute;chafaudage de
              pied&raquo;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;concerne les salari&eacute;s des entreprises et les
              travailleurs ind&eacute;pendants, amen&eacute;s &agrave;
              r&eacute;aliser les activit&eacute;s suivantes :&nbsp;</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 14pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Le montage et le d&eacute;montage d&rsquo;&eacute;chafaudages
                  de l&rsquo;entreprise</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >La v&eacute;rification r&eacute;glementaire
                  d&rsquo;&eacute;chafaudages (et la r&eacute;ception) de
                  l&rsquo;entreprise</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >L&rsquo;utilisation d&rsquo;&eacute;chafaudages (ou le
                  travail sur &eacute;chafaudages)&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >La v&eacute;rification journali&egrave;re
                  d&rsquo;&eacute;chafaudages (ou la maintenance)&nbsp;</span
                >
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le dispositif de formation &laquo;&eacute;chafaudage
              roulant&raquo;&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >concerne les salari&eacute;s des entreprises, et les travailleurs
              ind&eacute;pendants, amen&eacute;s &agrave; r&eacute;aliser les
              activit&eacute;s suivantes :&nbsp;</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 14pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Le montage et le d&eacute;montage de
                  l&rsquo;&eacute;chafaudage</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Les v&eacute;rifications de l&rsquo;&eacute;chafaudage</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height: 1.2; margin-top: 0pt; margin-bottom: 14pt; text-align: left;"
              >
                <span
                  style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >L&rsquo;utilisation de l&rsquo;&eacute;chafaudage (ou le
                  travail sur l&rsquo;&eacute;chafaudage)&nbsp;</span
                >
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Travail en hauteur</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation destin&eacute;e aux personnes amen&eacute;es
              d&rsquo;une fa&ccedil;on occasionnelle ou permanente &agrave;
              exercer toute ou partie d&rsquo;une activit&eacute; en hauteur
              (quelle que soit la hauteur) en utilisant des EPI antichute
              (harnais).</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >RPS Risques Psychosociaux</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Les risques psychosociaux dans l&apos;entreprise (stress,
              harc&egrave;lement, violence et, plus g&eacute;n&eacute;ralement,
              la souffrance au travail) font l&apos;objet d&apos;une demande
              sociale croissante.</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              ><br /></span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation vise &agrave; initier tous les acteurs de
              l&rsquo;entreprise &agrave; la pr&eacute;vention des RPS en leur
              transmettant une base de r&eacute;f&eacute;rence commune, et en
              leur permettant de structurer une d&eacute;marche de
              pr&eacute;vention : diagnostiquer, r&eacute;aliser le document
              unique, choisir un consultant, analyser un &eacute;v&egrave;nement
              grave. &nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >CATEC Pr&eacute;vention des risques professionnels li&eacute;s
              aux interventions en espace confin&eacute;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant de r&eacute;pondre aux besoins des
              employeurs, renforcer une culture commune de pratiques de
              pr&eacute;vention (Articles du code du travail L4121-1, L4121-4,
              L4121-5), et en s&rsquo;appuyant sur la recommandation du CTN-C
              R447 (adopt&eacute;e en 2009).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Electricit&eacute;&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Apporter les comp&eacute;tences en s&eacute;curit&eacute;
              n&eacute;cessaires au personnel d&rsquo;entreprise devant
              r&eacute;aliser des travaux non &eacute;lectriques dans un
              environnement &eacute;lectrique potentiellement dangereux.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;HOB0</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation Habilitation &Eacute;lectrique H0/B0 a pour but
              d&rsquo;apporter les comp&eacute;tences en s&eacute;curit&eacute;
              n&eacute;cessaires au personnel d&rsquo;entreprise devant
              r&eacute;aliser des travaux non &eacute;lectriques dans un
              environnement &eacute;lectrique potentiellement dangereux.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Habilitation &eacute;lectrique BH/BS/HE</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation Habilitation Electrique BS/BE/HE Manoeuvre a pour
              but de donner les comp&eacute;tences n&eacute;cessaires au
              personnel d&rsquo;entreprise devant r&eacute;aliser des travaux
              &eacute;lectriques simples.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Habilitation &eacute;lectrique BF/HF</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation est destin&eacute;e aux ex&eacute;cutants ou
              charg&eacute;s de chantier devant r&eacute;aliser des travaux
              d&rsquo;ordre non &eacute;lectrique &agrave; proximit&eacute;
              d&rsquo;une installation, d&rsquo;un ouvrage ou de canalisations
              enterr&eacute;es.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Incendie&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;EPI Equiper de premi&egrave;re intervention</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Acqu&eacute;rir les bons comportements et
              d&apos;apprendre &agrave; manipuler et &agrave; intervenir avec un
              extincteur sur un d&eacute;part de feu r&eacute;el.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;ESI Equipier de seconde intervention</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Approfondir les connaissances incendies des
              &eacute;quipiers de premi&egrave;re intervention. Elle a pour but
              de les former &agrave; l&apos;intervention dans l&apos;attente des
              sapeurs-pompiers.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Permis de feu : Formation&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >destin&eacute;e &agrave; tout collaborateur charg&eacute; de la
              r&eacute;daction, de l&rsquo;application des permis de feu ou de
              la surveillance de travaux par points chauds.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Equipier d&rsquo;&eacute;vacuation</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Membres du personnel qui tiendront les r&ocirc;les de
              guide-files et serre-files au sein de l&apos;entreprise afin de
              d&apos;assurer l&apos;&eacute;vacuation en toute
              s&eacute;curit&eacute; en cas d&apos;incendie.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Audit et signalisation de s&eacute;curit&eacute;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >,</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant d&rsquo;identifier les
              r&egrave;glementations qui imposent la signalisation et adapter
              l&apos;ensemble de la signalisation aux particularit&eacute;s de
              l&apos;&eacute;tablissement.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;R&eacute;glementation s&eacute;curit&eacute; incendie</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant de conna&icirc;tre la r&eacute;glementation
              s&eacute;curit&eacute; incendie applicable dans un
              &Eacute;tablissement Recevant du Public.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Unit&eacute; mobile de formation incendie</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation en&nbsp;unit&eacute; mobile pour former &agrave; la
              s&eacute;curit&eacute; incendie.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Syst&egrave;me de s&eacute;curit&eacute; incendie</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant d&rsquo;acqu&eacute;rir des connaissances
              th&eacute;oriques et pratiques n&eacute;cessaires pour exploiter
              le syst&egrave;me de s&eacute;curit&eacute; incendie de
              l&rsquo;&eacute;tablissement (alarme, d&eacute;tection,
              signalisation &hellip;)</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Incendie en &eacute;vacuation sanitaire</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant de pr&eacute;venir le risque
              d&rsquo;incendie, savoir r&eacute;agir face &agrave; une situation
              de feu naissant. Organiser l&apos;&eacute;vacuation des
              b&acirc;timents.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;SSIAP 1</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation pour toute personne souhaitant se qualifier en
              s&eacute;curit&eacute; incendie pour exercer des missions au sein
              d&rsquo;&eacute;tablissements recevant du public ou immeuble de
              grande hauteur (IGH).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;SSIAP 2</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation pour tout collaborateur dont la mission est
              d&rsquo;assurer la responsabilit&eacute; d&rsquo;une &eacute;quipe
              de s&eacute;curit&eacute; incendie d&rsquo;un &eacute;tablissement
              recevant du public ou immeuble de grande hauteur (IGH).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;SSIAP 3</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation SSIAP 3 concerne toute personne souhaitant devenir
              un Chef de Service de S&eacute;curit&eacute; Incendie au sein
              d&rsquo;un &eacute;tablissement recevant du publique (ERP) ou
              immeuble de grande hauteur (IGH).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Guide file et serre file</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation permettant de conna&icirc;tre les missions, les
              techniques et les consignes de l&rsquo;&eacute;vacuation
              (charg&eacute; de s&eacute;curit&eacute;, charg&eacute;
              d&rsquo;&eacute;vacuation, guides files et serres files). Il doit
              &eacute;galement conna&icirc;tre le signal d&rsquo;alarme, les
              cheminements d&rsquo;&eacute;vacuation et la conduite &agrave;
              tenir pour &eacute;vacuer les locaux.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >EPI Equipement de protection individuel</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Formation ARI</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Appareil respiratoire isolant : destin&eacute;e aux
              personnels &eacute;quip&eacute;s d&apos;appareils respiratoires
              isolants et qui sont amen&eacute;s par la nature de leurs
              fonctions &agrave; intervenir avec ce mat&eacute;riel en cas
              d&apos;alarme incendie ou de feu d&eacute;clar&eacute;. Permettre
              au personnel d&apos;&eacute;voluer dans un air vici&eacute; afin
              d&apos;effectuer certaines t&acirc;ches en toute
              s&eacute;curit&eacute;.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Conduite d&rsquo;engins</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >CACES</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;ou&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Certificat d&rsquo;Aptitude &agrave; la Conduite En
              S&eacute;curit&eacute;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;est un certificat qui atteste la connaissance
              professionnelle et l&rsquo;aptitude &agrave; conduire en
              s&eacute;curit&eacute; une certaine cat&eacute;gorie
              d&rsquo;engins de chantier et de manutention.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;R482 Engins de chantier</span
            >
          </p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie A</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Pelles hydrauliques, &agrave; chenilles ou sur pneumatiques, de
              masse &le; 6 tonnes.</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chargeuses, &agrave; chenilles ou sur pneumatiques de masse
              &le; 6 tonnes.</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chargeuse-pelleteuse de masse &le; 6 tonnes,</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Moto-basculeurs de masse &le; 6 tonnes.</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Compacteurs de masse &le; 6 tonnes.</span
            >
          </h3>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >-Tracteurs agricoles de puissance &le; 100 cv (73.6 kW).</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie B1</span
            >
          </h3>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Pelles hydrauliques, &agrave; chenilles ou sur pneumatiques, de
              masse &gt; 6 tonnes.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Pelles multifonctions.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie B2</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Machines automotrices de sondage ou de forage.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie B3</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Pelles hydrauliques rail-route &agrave; d&eacute;placement
              s&eacute;quentiel.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie C1</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chargeuses sur pneumatiques, de masse &gt; 6 t.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chargeuses-pelleteuses de masse &gt; 6 t</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie C2</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Bouteurs.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chargeuses &agrave; chenilles de masse &gt; 6 t.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie C3</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Niveleuses automotrices</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie D</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Compacteurs, &agrave; cylindres, &agrave; pneumatiques ou
              mixtes, de masse &gt; 6 t.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Compacteurs &agrave; pieds dameurs de masse &gt; 6 t</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie E</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Tombereaux, rigides ou articul&eacute;s.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Moto-basculeurs de masse &gt; 6t.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Tracteurs agricoles de puissance &gt; 100 cv (73.6 kW).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie F</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chariots de manutention tout-terrain &agrave; conducteur
              port&eacute; &agrave; m&acirc;t.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- Chariots de manutention tout-terrain &agrave; conducteur
              port&eacute; &agrave; fl&egrave;che t&eacute;lescopique.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie G</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >- D&eacute;placement et chargement / d&eacute;chargement sur
              porte-engins des engins de chantier des cat&eacute;gories A
              &agrave; F, sans activit&eacute; de production, pour
              d&eacute;monstration ou essais.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >R483 Grues mobiles</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie A</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Grue mobile &agrave; fl&egrave;che treillis</span
            >
          </h3>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Grue automotrice &agrave; fl&egrave;che treillis qui peut
              &ecirc;tre mont&eacute;e sur un m&acirc;t (tour) capable de se
              d&eacute;placer en charge ou &agrave; vide sans avoir besoin de
              voie de roulement fixe et qui demeure stable sous
              l&rsquo;influence de la gravit&eacute;.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie B</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Grue mobile &agrave; fl&egrave;che
              t&eacute;lescopique</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Grue automotrice &agrave; fl&egrave;che t&eacute;lescopique qui
              peut &ecirc;tre mont&eacute;e sur un m&acirc;t (tour), capable de
              se d&eacute;placer en charge ou &agrave; vide sans avoir besoin de
              voie de roulement fixe et qui demeure stable sous
              l&rsquo;influence de la gravit&eacute;.</span
            >
          </p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >R484 Ponts roulants et portiques</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 1</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Pont roulant commande au sol</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 2</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Pont roulant commande en cabine</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >R485 Chariots de manutention automoteurs gerbeurs &agrave;
              conducteur accompagnant</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 1&nbsp;:</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Gerbeurs automoteurs &agrave; conducteur accompagnant (1,20
              m &lt;hauteur de lev&eacute;e &le; 2,50 m)</span
            ><span
              style="font-size:12.499999999999998pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 2&nbsp;:</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Gerbeurs automoteurs &agrave; conducteur accompagnant
              (hauteur de lev&eacute;e &gt; 2,50 m).</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >R486 PEMP (Plateforme &eacute;l&eacute;vatrice mobile de
              personnel)</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Groupe A</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: &eacute;l&eacute;vation verticale.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Groupe B</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: &eacute;l&eacute;vation multidirectionnelle
              (D&eacute;port possible).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Type 1</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: la translation du ch&acirc;ssis ou du porteur n&rsquo;est
              possible que si la PEMP est en configuration transport (Position
              basse) = utilisation &agrave; poste fixe.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Type 3</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: la translation peut &ecirc;tre command&eacute;e par un
              organe situ&eacute; sur la plate-forme de travail lorsque celle-ci
              est en position haute = automotrice.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie A&nbsp;:</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;PEMP du groupe A de type 1 ou 3.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie B :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >PEMP du groupe B de type 1 ou 3.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie C :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Conduite hors production des PEMP des Cat A ou B
              D&eacute;placement, chargement / d&eacute;chargement sur
              porte-engins, transfert de toutes les PEMP de Cat A ou B sans
              activit&eacute; de production, pour la maintenance, pour
              d&eacute;monstrations ou pour essais.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <h3
            dir="ltr"
            style="line-height: 1.38; margin-top: 2pt; margin-bottom: 0pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#243f61;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >R487 Grues &agrave; tour</span
            >
          </h3>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 1</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Grue &agrave; tour &agrave; montage par
              &eacute;l&eacute;ments, &agrave; fl&egrave;che distributrice
              (GME).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 2</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Grue &agrave; tour &agrave; montage par
              &eacute;l&eacute;ments, &agrave; fl&egrave;che relevable.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.38; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 3</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;: Grue &agrave; tour &agrave; montage automatis&eacute;
              (GMA).</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;R489 Chariots de manutention automoteurs &agrave;
              conducteur port&eacute;</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 1A :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Pr&eacute;parateurs de commande sans &eacute;l&eacute;vation du
              poste de conduite (hauteur de lev&eacute;e &le; 1,20 m).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 1B :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Gerbeurs &agrave; conducteur port&eacute; (hauteur de
              lev&eacute;e &gt; 1,20 m).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 2A :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Chariots &agrave; plateau porteur (capacit&eacute; de charge &le;
              2 tonnes).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 2B :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Chariots tracteurs industriels (capacit&eacute; de traction &le;
              25 tonnes).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 3 :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Chariots &eacute;l&eacute;vateurs frontaux en porte &agrave; faux
              (capacit&eacute; nominale &le; 6 tonnes).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 4 :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Chariots &eacute;l&eacute;vateurs frontaux en porte &agrave; faux
              (capacit&eacute; nominale &gt; 6 tonnes).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 5 :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Chariots &eacute;l&eacute;vateurs &agrave; m&acirc;t
              r&eacute;tractable.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 6 :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&Eacute;l&eacute;vateurs &agrave; poste de conduite
              &eacute;levable (hauteur de plancher &gt; 1,20 m).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cat&eacute;gorie 7 :&nbsp;</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Conduite hors production des chariots de toutes les
              cat&eacute;gories.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;R490 GACV</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Grues de chargement (grues mont&eacute;es sur un v&eacute;hicule
              industriel ayant une capacit&eacute; r&eacute;siduelle
              d&apos;emport de charges, con&ccedil;ues pour le chargement et le
              d&eacute;chargement du v&eacute;hicule).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Option relative aux grues de chargement&nbsp;: conduite au moyen
              d&apos;une t&eacute;l&eacute;commande.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Testeur CACES</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <br />
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Les permis</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis BE</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis BE est indispensable pour conduire une voiture dont le
              PTAC ne d&eacute;passe pas 3.5 tonnes attel&eacute;e d&rsquo;une
              remorque&nbsp;dont le&nbsp;PTAC est sup&eacute;rieur &agrave; 750
              kg et inf&eacute;rieur ou &eacute;gal &agrave; 3&nbsp;500 kg,
              lorsque la somme des PTAC (voiture + remorque) est
              sup&eacute;rieure &agrave; 4&nbsp;250 kg.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis C</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis poids lourds de cat&eacute;gorie C permet de conduire
              des v&eacute;hicules dont le PTAC est sup&eacute;rieur &agrave;
              7,5 tonnes, con&ccedil;us pour le transport de 8 passagers maximum
              en plus du conducteur. Le v&eacute;hicule peut &ecirc;tre
              attel&eacute; d&apos;une remorque dont le PTAC est
              inf&eacute;rieur ou &eacute;gal &agrave; 750 kg. L&rsquo;&acirc;ge
              minimal pour s&rsquo;inscrire &agrave; cet examen est de 21 ans.
              Les personnes suivant une formation professionnelle de conducteur
              peuvent passer ce permis d&egrave;s 18 ans. Pour passer la
              cat&eacute;gorie C, il faut &ecirc;tre au pr&eacute;alable
              titulaire de la cat&eacute;gorie B.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis C1</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Un v&eacute;hicule dont le PTAC est compris entre 3,5 et 7,5
              tonnes et con&ccedil;u pour le transport de 8 passagers maximum en
              plus du conducteur, doit &ecirc;tre conduit par une personne
              titulaire d&rsquo;un permis C1. Une remorque dont le PTAC est
              inf&eacute;rieur ou &eacute;gal &agrave;&nbsp;750 kg peut
              &ecirc;tre attel&eacute;e &agrave; ce v&eacute;hicule.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >L&rsquo;&acirc;ge minimal pour se pr&eacute;senter &agrave;
              l&rsquo;examen du permis de cat&eacute;gorie C1 est de 18 ans. Il
              faut &ecirc;tre au pr&eacute;alable titulaire de la
              cat&eacute;gorie B.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis C1E</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >D&eacute;tenir le permis C1E permet de conduire un
              v&eacute;hicule dont le PTAC se situe entre 3,5 et 7,5 tonnes
              attel&eacute; d&rsquo;une remorque ou semi-remorque dont le PTAC
              minimal est de 750 kg. Ce permis permet &eacute;galement de
              conduire une voiture ou camionnette dont le PTAC ne d&eacute;passe
              pas 3.5 tonnes, attel&eacute;e d&rsquo;une remorque dont le
              PTAC&nbsp;est sup&eacute;rieur &agrave;&nbsp;3&nbsp;500 kg.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis D</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis D permet de conduire un v&eacute;hicule con&ccedil;u
              pour transporter plus de 8 passagers assis sans compter le
              conducteur. Ce v&eacute;hicule peut &ecirc;tre attel&eacute;
              d&rsquo;une remorque d&rsquo;un PTAC n&rsquo;exc&eacute;dant pas
              750 kg. L&rsquo;&acirc;ge minimal pour passer le permis D est de
              24 ans. Autre condition&nbsp;: &ecirc;tre titulaire du permis
              B.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis D1</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis D1 permet de conduire un v&eacute;hicule d&rsquo;une
              longueur inf&eacute;rieure ou &eacute;gale &agrave; 8
              m&egrave;tres. Le nombre de si&egrave;ges, outre celui du
              conducteur, est de 16 places au maximum. Une remorque d&rsquo;un
              PTAC maximal de 750 kg peut &ecirc;tre attel&eacute;e.
              L&rsquo;&acirc;ge minimal pour&nbsp;se pr&eacute;senter &agrave;
              l&apos;examen du permis de cat&eacute;gorie D1 est de 24 ans.
              &Ecirc;tre titulaire du permis B est indispensable.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis D1E</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis D1E permet de conduire un v&eacute;hicule de 8
              m&egrave;tres maximum et de 16 places au plus, attel&eacute;
              d&apos;une remorque dont le PTAC est sup&eacute;rieur &agrave; 750
              kg.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >L&apos;&acirc;ge minimal pour passer le permis D1E est de 21 ans
              et il faut &ecirc;tre d&eacute;j&agrave; titulaire du permis
              D1.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Le permis DE</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Le permis DE permet de conduire un v&eacute;hicule de plus de 8
              m&egrave;tres et de plus de 16 places attel&eacute; d&apos;une
              remorque dont le PTAC est sup&eacute;rieur &agrave; 750 kg.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Comme le permis D, l&apos;&acirc;ge minimal pour passer le permis
              DE est de 24 ans (sauf formation professionnelle du
              conducteur).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;La FIMO</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La&nbsp;</span
            ><a
              href="http://www.forget-formation.com/trouver-une-formation/fimo-marchandises"
              style="text-decoration:none;"
              ><span
                style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >FIMO Marchandises</span
              ></a
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La FIMO (Formation Initiale Minimum Obligatoire) marchandises
              s&rsquo;adresse aux&nbsp;</span
            ><a
              href="http://www.forget-formation.com/decouvrir-un-metier/conducteur-routier-de-poids-lourd"
              style="text-decoration:none;"
              ><span
                style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >conducteurs de v&eacute;hicules de transport de
                marchandises</span
              ></a
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >. Cette formation est &agrave; destination des conducteurs
              d&eacute;butants.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La&nbsp;</span
            ><a
              href="http://www.forget-formation.com/trouver-une-formation/fimo-voyageurs"
              style="text-decoration:none;"
              ><span
                style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >FIMO Voyageurs</span
              ></a
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La FIMO (Formation Initiale Minimum Obligatoire) voyageurs
              s&rsquo;adresse aux&nbsp;</span
            ><a
              href="http://www.forget-formation.com/decouvrir-un-metier/conducteur-de-vehicule-de-transport-en-commun"
              style="text-decoration:none;"
              ><span
                style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >conducteurs de v&eacute;hicules de transport en commun</span
              ></a
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >. Cette formation est &agrave; destination des conducteurs
              d&eacute;butants.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;La FCO</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La FCO est la Formation Continue Obligatoire, d&rsquo;une
              dur&eacute;e de 35 heures, renouvelable tous les 5 ans. Elle
              permet au conducteur d&rsquo;actualiser ses connaissances et
              parfaire sa pratique en mati&egrave;re de s&eacute;curit&eacute;
              et de r&eacute;glementation professionnelle. Elle est le recyclage
              de la FIMO.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Transport</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;APS TRM</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Former des salari&eacute;s &agrave; &ecirc;tre des acteurs de
              Pr&eacute;vention et Secours du Transport Routier de
              Marchandises</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;APS RV</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Former des salari&eacute;s &agrave; &ecirc;tre des acteurs de
              pr&eacute;vention et Secours du Transport Routier de
              Voyageurs.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <br />
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >S&eacute;curit&eacute;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Cynophile&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cette formation permet d&apos;exercer des missions de
              s&eacute;curit&eacute; priv&eacute;e avec un chien (Intervention,
              d&eacute;fense, recherche, ...).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;CQP APS</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Toute personne souhaitant exercer les fonctions d&rsquo;agent de
              s&eacute;curit&eacute; priv&eacute;e.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;TP A2SP</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >La formation pr&eacute;pare au titre professionnel Agent de
              s&ucirc;ret&eacute; et de s&eacute;curit&eacute;
              priv&eacute;.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Attentat et terrorisme</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Cette formation permet de vous pr&eacute;parer psychologiquement
              et techniquement en cas d&apos;attentat ou de mouvement de foule.
              Pr&eacute;parer vos &eacute;quipes &agrave; assurer leur
              s&eacute;curit&eacute; et celle du public. Appr&eacute;hender la
              conduite &agrave; tenir en cas d&apos;attentat.</span
            >
          </p>
          <p style="text-align: left;"><br /></p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:24pt;font-family:'Helvetica Neue',sans-serif;color:#44ae95;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >A.I.P.R</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 14pt; margin-bottom: 14pt; text-align: left;"
          >
            Autorisation d’Intervention à Proximité des Réseaux obligatoire
            depuis le 1er janvier 2018, pour les personnels intervenant à
            proximité des réseaux.
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Opérateur
            </span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Salarié intervenant directement dans les travaux à proximité des
              réseaux aériens ou enterrés, soit en tant qu'opérateur d'engin,
              soit dans le cadre de travaux urgents</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Concepteur
            </span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Salarié du maître d'ouvrage ou du maître d’œuvre devant
              intervenir en préparation ou suivi des projets de travaux.
            </span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:Wingdings;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >❖</span
            ><span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;Encadrant
            </span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <span
              style="font-size:14pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Salarié de l'entreprise de travaux intervenant en préparation
              administrative et technique des travaux (chef de chantier,
              conducteur de travaux).
            </span>
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 10pt; text-align: left;"
          >
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Formation",
};
</script>

<style scoped>
.form-wrapping {
  padding: 0rem 5rem 0rem;
}
</style>
