<template>
  <div>
    <v-expansion-panels
      style="padding: 5px; justify-content: center; text-align: center;"
      multiple
      focusable
      popout
      v-model="selectedTabs"
    >
      <v-expansion-panel key="1">
        <v-expansion-panel-header class="head">
          Créer une formation
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form style="padding-top: 20px">
            <v-row>
              <v-col cols="12" sm="12" md="4"
                >Date et heure du début de la formation :</v-col
              >
              <v-col cols="12" sm="12" md="8">
                <VueCtkDateTimePicker
                  id="HeureDebut"
                  v-model="debutHeure"
                  locale="fr"
                  format="YYYY-MM-DD HH:mm:ss"
                  value="2018-04-07 20:26"
                  title="Début de la disponbilité"
                  description="Début de la disponbilité"
                />
              </v-col>
              <v-col cols="12" sm="12" md="4"
                >Date et heure de la fin de la formation :</v-col
              >
              <v-col cols="12" sm="12" md="8">
                <VueCtkDateTimePicker
                  id="HeureFin"
                  v-model="finHeure"
                  locale="fr"
                  format="YYYY-MM-DD HH:mm:ss"
                  value="2018-04-07 20:26"
                  title="Fin de la disponbilité"
                  description="Fin de la disponbilité"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="name"
                  label="Nom de la formation"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  style="padding-top: 4px"
                  v-model="domain"
                  clearable
                  label="Domaine d'intervention"
                  :items="domainsListe"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field
                  v-model="lieu"
                  label="Adresse de la formation"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-text-field v-model="city" label="Ville"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="departements"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  label="Département"
                  :items="departementsListe"
                  item-text="name"
                  item-value="id"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="description"
                  label="Description"
                  counter
                  maxlength="800"
                  full-width
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="number"
                  v-model="maxPlace"
                  label="Nombre de place max"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="number"
                  v-model="minPlace"
                  label="Nombre de place min"
                ></v-text-field>
              </v-col>
              <div class="col-12 form-group">
                <v-checkbox v-model="interne" label="Formateur interne">
                </v-checkbox>
              </div>
              <v-col cols="12" sm="12" md="12" v-if="interne">
                <v-text-field
                  v-model="formateur_int"
                  label="Nom du formateur interne"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-btn color="#00897F" text elevation="1" @click="saveTime"
                  >Sauvegarder</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="2">
        <v-expansion-panel-header class="head">
          Agenda
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchDetailAgenda
            :events="slot"
            :onEventClicked="handleEventClicked"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="3" @click="fetchMyFormation">
        <v-expansion-panel-header class="head">
          Mes formation en cours: {{ incommingFormation.size_max }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-container class="max-width">
              <v-pagination
                @input="SerchMyFormation"
                v-model="myFormationPage"
                class="my-4"
                :length="myFormationLength"
                :total-visible="7"
              ></v-pagination>
            </v-container>
            <v-col
              v-for="(reservation, index) in incommingFormation.events"
              :key="index"
              cols="12"
              md="6"
              sm="12"
            >
              <v-card elevation="1" max-height="400px" class="overflow-y-auto">
                <v-card-title class="justify-center">
                  Formation: {{ reservation.name }} <br />le
                  {{ dateFromat(reservation.start) }}
                  <br />
                  Nombre de participants:
                  {{ reservation.nb_place_max - reservation.nb_place_rest }} /
                  {{ reservation.nb_place_max }}
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-group
                      v-if="reservation.formative"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Formateur</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item v-if="reservation.formative_id">
                        <v-list-item-content>
                          <p>Nom: {{ reservation.formative.last_name }}</p>
                          <p>Prénom: {{ reservation.formative.first_name }}</p>
                          <p>
                            Adresse mail:
                            {{ reservation.formative.contact.email }}
                          </p>
                          <p>
                            Téléphone: {{ reservation.formative.contact.phone }}
                          </p>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon
                            @click="deleteFormative(reservation.id, index)"
                            >mdi-delete
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item v-else>
                        <v-list-item-content>
                          <p>Nom: {{ reservation.formative_interne }}</p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group no-action sub-group>
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Entreprises</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="(entreprise,
                        index_entreprise) in reservation.reservations"
                        :key="index_entreprise"
                        link
                      >
                        <v-list-item-content v-if="entreprise">
                          <p>Nom: {{ entreprise.contact.name }}</p>
                          <p>
                            Nombre de place réservées:
                            {{ entreprise.pivot.nb_place }}
                          </p>
                          <p>
                            Adresse mail:
                            {{ entreprise.email }}
                          </p>
                          <p>Téléphone: {{ entreprise.phone }}</p>
                        </v-list-item-content>
                        <v-list-item-icon v-if="entreprise">
                          <v-icon
                            @click="
                              deleteEntreprise(
                                reservation.id,
                                entreprise.id,
                                index,
                                index_entreprise
                              )
                            "
                            >mdi-delete
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="4">
        <v-expansion-panel-header class="head">
          Demandes de réservation entreprise
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-col
              v-for="reservation in reservRequest"
              :key="
                reservation.user_id / reservation.event_id -
                  reservation.nb_place
              "
              cols="12"
              md="6"
              sm="12"
            >
              <v-card elevation="1" max-height="400px" class="overflow-y-auto">
                <v-card-title class="justify-center">
                  Réservation {{ reservation.event.name }} le
                  {{ dateFromat(reservation.event.start) }}
                  <br />
                  de {{ reservation.nb_place }} par
                  {{ reservation.user.identifiant }}
                </v-card-title>
                <v-card-text>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span style="text-align: center"> Détails </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="padding-top: 20px">
                      <div>
                        <h5>Demande</h5>
                        <p>Nom: {{ reservation.user.identifiant }}</p>
                        <p>Adresse mail{{ reservation.user.email }}</p>
                        <p>Téléphone: {{ reservation.user.phone }}</p>
                        <p>
                          Nombre de place reservé: {{ reservation.nb_place }}
                        </p>
                        <v-divider></v-divider>
                        <h5>Détails formation</h5>
                        <p>
                          Nombre de place max:
                          {{ reservation.event.nb_place_max }}
                        </p>
                        <p>
                          Nombre de place minimum:
                          {{ reservation.event.nb_place_max }}
                        </p>
                        <p>
                          Nombre de place restante:
                          {{ reservation.event.nb_place_rest }}
                        </p>
                        <p></p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="green"
                    text
                    @click="
                      AcceptReservation({
                        user_id: reservation.user_id,
                        event_id: reservation.event_id,
                      })
                    "
                  >
                    Accepter
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    @click="
                      RejectReservation({
                        user_id: reservation.user_id,
                        event_id: reservation.event_id,
                      })
                    "
                  >
                    Refuser
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="5" @click="fetchMyFormativeRequest">
        <v-expansion-panel-header class="head">
          Demande de réservation de formateur
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-col
              v-for="(formativeReservation, i) in formativeRequest"
              :key="i"
              cols="12"
              md="6"
              sm="12"
            >
              <v-card
                v-if="formativeReservation"
                elevation="1"
                max-height="400px"
                class="overflow-y-auto"
              >
                <v-card-title class="justify-center">
                  Formation {{ formativeReservation.event.name }} le
                  {{ dateFromat(formativeReservation.event.start) }}
                  <br />
                </v-card-title>
                <v-card-text formativeReservation.formative>
                  <p>
                    Nom du formateur:
                    {{ formativeReservation.formative.first_name }}
                    {{ formativeReservation.formative.last_name }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="green"
                    text
                    :to="{
                      name: 'former-id',
                      params: {
                        id: formativeReservation.formative.id,
                      },
                    }"
                  >
                    Vers le profil
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    :to="{
                      name: 'former-id',
                      params: {
                        id: formativeReservation.formative.id,
                      },
                    }"
                  >
                    Annuler
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <EventConfModal
      v-if="dialogOpen"
      :opened="dialogOpen"
      :event="selectedEvent"
      :onClose="handleModalClosed"
      :onDelete="handleEventDelete"
    />
  </div>
</template>

<script>
//Agenda
import SearchDetailAgenda from "@/views/Search/Detail/SearchDetailAgenda";
import { mapGetters, mapActions } from "vuex";
import EventConfModal from "../OrganismeConf/EventConfModal.vue";
import * as dayjs from "dayjs";

export default {
  name: "OrgFormCalendar",
  components: { SearchDetailAgenda, EventConfModal },
  created() {
    this.departementsListe = this.dep;
  },
  data: () => ({
    selectedTabs: [1],
    add: false,
    dialogOpen: false,
    finHeure: null,
    debutHeure: null,
    description: "",
    name: "",
    city: "",
    lieu: "",
    formateur_int: null,
    maxPlace: null,
    minPlace: null,
    interne: false,
    domain: null,
    departements: [],
    departementsListe: [],
    selectedEvent: {},
    isMyFormationFetched: false,
    myFormationPage: 1,
    isMyFormativeFetched: false,
  }),
  mounted() {
    this.fetchMyCalendar();
    this.fetchReservRequest();
  },
  computed: {
    ...mapGetters("organism", {
      slot: "getCalendar",
      reservRequest: "getReservRequest",
      incommingFormation: "getMyEvent",
      formativeRequest: "getFormativeRequest",
    }),
    myFormationLength() {
      return Math.ceil(this.incommingFormation.size_max / 10);
    },
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
  },
  methods: {
    dateFromat(date) {
      return dayjs(date, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    AcceptReservation(ids) {
      this.reservationAccept(ids);
    },
    RejectReservation(ids) {
      this.reservationRefuse(ids);
    },
    saveTime() {
      let body = {
        start: this.debutHeure,
        end: this.finHeure,
        name: this.name,
        desc: this.description,
        nb_place_max: this.maxPlace,
        nb_place_min: this.minPlace,
        domain_id: this.domain,
        formative_interne: this.formateur_int,
        department: this.departements,
        city: this.city,
        address: this.lieu,
      };
      this.add = false;
      this.addEvent(body)
        .then((response) => {
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error.split('"')[3], color: "red" });
        });
    },
    handleEventClicked(event) {
      this.selectedEvent = event;
      this.dialogOpen = true;
    },
    handleModalClosed() {
      this.selectedEvent = {};
      this.dialogOpen = false;
    },
    handleEventDelete() {
      this.dialogOpen = false;
      this.deleteEvent({ event_id: this.selectedEvent.id });
    },
    fetchMyFormation() {
      if (this.isMyFormationFetched === true) {
        this.isMyFormationFetched = false;
        return;
      }
      this.isMyFormationFetched = true;
      this.fetchMyEvent({ limit: 10, offset: 0 });
    },
    fetchMyFormativeRequest() {
      if (this.isMyFormativeFetched === true) {
        this.isMyFormativeFetched = false;
        return;
      }
      this.isMyFormativeFetched = true;
      this.fetchFormativeRequest();
    },
    SerchMyFormation() {
      this.fetchMyEvent({ limit: 10, offset: this.myFormationPage - 1 });
    },
    deleteFormative(id, index) {
      this.deleteEventFormative({ id: id, index: index });
    },
    deleteEntreprise(event_id, user_id, index, indexEntreprise) {
      this.deleteEventReservation({
        event_id: event_id,
        user_id: user_id,
        index: index,
        indexEntreprise: indexEntreprise,
      });
    },
    ...mapActions("organism", [
      "addEvent",
      "fetchMyEvent",
      "fetchMyCalendar",
      "fetchReservRequest",
      "fetchFormativeRequest",
      "reservationAccept",
      "reservationRefuse",
      "deleteEvent",
      "deleteEventFormative",
      "deleteEventReservation",
    ]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: #00897f;
  color: white;
}

p {
  text-align: left;
}
</style>
