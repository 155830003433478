<template>
  <v-container>
    <v-expansion-panels multiple focusable popout>
      <v-expansion-panel v-for="(item, i) in competencies" :key="i">
        <v-expansion-panel-header class="head">
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="item.sub === false">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-if="readOnly === false">
                    Sélection
                  </th>
                  <th class="text-left">
                    Domaine de formation
                  </th>
                  <th class="text-left">
                    {{ tarif_1_name }}
                  </th>
                  <th class="text-left">
                    {{ tarif_2_name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in item.data" :key="item.label">
                  <td v-if="readOnly === false">
                    <v-checkbox v-model="item.check"> </v-checkbox>
                  </td>
                  <td>{{ item.label }}</td>
                  <td>
                    <v-text-field
                      :disabled="!item.check"
                      :readonly="readOnly"
                      v-model="item.tarif_1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      :disabled="!item.check"
                      :readonly="readOnly"
                      v-model="item.tarif_2"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          <v-expansion-panel v-for="(item, i) in item.data" :key="i">
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" v-if="readOnly === false">
                        Sélection
                      </th>
                      <th class="text-left">
                        Domaine de formation
                      </th>
                      <th class="text-left">
                        {{ tarif_1_name }}
                      </th>
                      <th class="text-left">
                        {{ tarif_2_name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in item.data" :key="item.label">
                      <td v-if="readOnly === false">
                        <v-checkbox v-model="item.check"> </v-checkbox>
                      </td>
                      <td>{{ item.label }}</td>
                      <td>
                        <v-text-field
                          :disabled="!item.check"
                          :readonly="readOnly"
                          v-model="item.intra"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :disabled="!item.check"
                          :readonly="readOnly"
                          v-model="item.iner"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="type !== 0">
      <p style="color: grey; font-size: smaller; padding-left: 15px">
        (*) - intra: tarif groupe pour un entreprise (**) - inter: tarif par
        personne
      </p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "competencieTable",
  props: {
    competencies: {
      required: true,
    },
    readOnly: {
      required: false,
      default: false,
    },
    type: {
      required: false,
      default: 0,
    },
  },
  computed: {
    tarif_1_name() {
      if (this.type === 0) return "Tarif horaire";
      return "Tarif intra(*)";
    },
    tarif_2_name() {
      if (this.type === 0) return "Tarif journalier";
      return "Tarif inter(**)";
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: #00897f;
  color: white;
}
</style>
