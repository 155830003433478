<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          label="Nouveau mot de passe"
          hint="Au moins 8 characters"
          v-model="newPassword"
          class="input-group--focused"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show3 ? 'text' : 'password'"
          label="Confirmer le nouveau mot de passe"
          hint="Au moins 8 characters"
          v-model="confNewPassword"
          class="input-group--focused"
          @click:append="show3 = !show3"
        ></v-text-field>
      </v-col>
      <v-col sm="8"></v-col>
      <v-col sm="4">
        <v-btn depressed dark color="#00897f" @click="change">
          Valider
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PasswordChange",
  data() {
    return {
      newPassword: "",
      confNewPassword: "",
      show1: false,
      show2: false,
      show3: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  methods: {
    change() {
      var pass = {
        password: this.newPassword,
        password_confirmation: this.confNewPassword,
      };
      this.changeUserPassword(pass);
    },
    ...mapActions("user", ["changeUserPassword"]),
  },
};
</script>
