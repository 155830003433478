import router from "../../router/index";

const getDefaultUser = () => {
  return {};
};

const state = {
  user: null,
  access_token: null,
  id: null,
  profil: null,
};

state.user = getDefaultUser();

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getProfil: (state) => {
    return state.profil;
  },
  isAuthenticated(state) {
    return !!state.access_token;
  },
};

const mutations = {
  login(state, payload) {
    state.user = payload.user ?? null;
    state.profil = payload.profil ?? null;
    state.access_token = payload.token ?? null;
    state.id = payload.id ?? null;
  },
  logout(state) {
    state.user = null;
    state.profil = null;
    state.access_token = null;
    state.id = null;
  },
  setToken(state, data) {
    state.access_token = data.token;
  },
  validateEmail(state, data) {
    state.user.email_verified_at = data;
  },
};

const actions = {
  registerFormateur({ commit }, user) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("register/formative", user)
        .then(function(response) {
          commit("register");
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  registerEntreprise({ commit }, user) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("register/enterprise", user)
        .then(function(response) {
          commit("register");
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  registerOrganisme({ commit }, user) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("register/organism", user)
        .then(function(response) {
          commit("register");
          resolve(response);
        })
        .catch(function(error) {
          reject(Object.values(error.response.data).toString());
        });
    });
  },
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("login", user)
        .then(function(response) {
          localStorage.setItem("userToken", response.data.access_token);
          localStorage.setItem("userId", response.data.user.id);
          commit("login", {
            user: response.data.user,
            profil: response.data.category_user,
            token: response.data.access_token,
            id: response.data.user.id,
          });

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit("logout");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");

    router.replace("/");
  },
  getUserById({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("my_profile")
        .then(function(response) {
          commit("login", {
            user: response.data.user,
            profil: response.data.category_user,
            token: localStorage.getItem("userToken"),
            id: response.data.user.id,
          });

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  tryAutoLogin({ commit, dispatch }) {
    const token = localStorage.getItem("userToken");
    if (!token) {
      commit("setToken", { token: null });
      return;
    }
    commit("setToken", { token: token });
    dispatch("getUserById");
  },
  emailValidation({ commit }, token) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("user/verify/" + token)
        .then(function(response) {
          commit("validateEmail", { id: 1 });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  changeUserPassword(_, pass) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("change-password", pass)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  userPayInformation(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("transaction?type=" + data.type + "&porteur=" + data.email)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  userUnsubscribe() {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("/unsubscribe")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  sendMail(_, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("/email-verification/" + id + "/patch")
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
