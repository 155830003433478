<template>
  <div>
    <v-expansion-panels
      style="padding: 5px; justify-content: center; text-align: center;"
      multiple
      focusable
      popout
    >
      <v-expansion-panel key="1">
        <v-expansion-panel-header class="head">
          Agenda
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SearchDetailAgenda
            :events="slots"
            :onEventClicked="handleEventClicked"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="2">
        <v-expansion-panel-header class="head">
          Mes demandes de formations en atttente
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="padding-top: 15px">
            <v-col
              v-for="(event, index) in waitings"
              :key="index"
              cols="12"
              md="4"
              sm="12"
            >
              <v-card elevation="1" max-height="400px" class="overflow-y-auto">
                <v-card-title class="text">
                  {{ event.name }} par
                  {{ event.creator.contact.name }}
                </v-card-title>

                <v-card-text>
                  <p>
                    <b> Domaine :</b>
                    {{ domainsListe[event.domain_id - 1].name }}
                  </p>
                  <p><b> Description :</b> {{ event.desc }}</p>
                  <p>
                    Du {{ dateFrom(event.start) }} Au
                    {{ dateTo(event.end) }}
                  </p>
                  <p><b> Adresse :</b> {{ event.address }}, {{ event.city }}</p>
                  <p v-if="event.creator.contact.qualiopi === 1">
                    Certifié qualiopi
                  </p>
                  <p>
                    <v-icon>mdi-account</v-icon> Nombre de place demandé:
                    {{ event.pivot.nb_place }} | Place restante:
                    {{ event.nb_place_rest }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    color="red"
                    dark
                    @click="refuseEvent(event.id, index)"
                  >
                    Annuler
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row></v-expansion-panel-content
        >
      </v-expansion-panel>
    </v-expansion-panels>
    <EventConfModal
      v-if="dialogOpen"
      :opened="dialogOpen"
      :event="selectedEvent"
      :onClose="handleModalClosed"
      :onDelete="handleEventDelete"
    />
  </div>
</template>

<script>
//Agenda
import SearchDetailAgenda from "@/views/Search/Detail/SearchDetailAgenda";
import { mapGetters, mapActions } from "vuex";
import EventConfModal from "./EventInfoModal.vue";
import * as dayjs from "dayjs";

export default {
  name: "OrgFormCalendar",
  components: { SearchDetailAgenda, EventConfModal },
  data: () => ({
    add: false,
    dialogOpen: false,
    finHeure: null,
    debutHeure: null,
    description: "",
    name: "",
    formateur_int: null,
    maxPlace: null,
    minPlace: null,
    interne: false,
    domain: null,
    selectedEvent: {},
  }),
  mounted() {
    this.fetchEvent();
    this.fetchWaitingEvent();
  },
  computed: {
    ...mapGetters("enterprise", {
      slots: "getMyEvent",
      waitings: "getMyWaitingEvent",
    }),
    ...mapGetters("user", { user: "getUser", profil: "getProfil" }),
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
  },
  methods: {
    dateFrom(start) {
      return dayjs(start, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    dateTo(end) {
      return dayjs(end, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    infoStatut(active, formative_id) {
      if (active === 1) {
        return "Formation Plannifiée";
      } else if (active === 0 && formative_id !== null) {
        return "En attente de confirmation du formateur";
      }
    },
    handleEventClicked(event) {
      this.selectedEvent = event;
      this.dialogOpen = true;
    },
    handleModalClosed() {
      this.selectedEvent = {};
      this.dialogOpen = false;
    },
    handleEventDelete() {
      this.cancelEvent(this.selectedEvent.id);
      this.dialogOpen = false;
    },
    cancelEvent(id) {
      this.deleteEventReservation({
        user_id: this.user.id,
        event_id: id,
      });
    },
    refuseEvent(id, index) {
      this.refuseEventReservation({
        user_id: this.user.id,
        event_id: id,
        index: index,
      });
    },
    ...mapActions("enterprise", [
      "fetchEvent",
      "fetchWaitingEvent",
      "deleteEventReservation",
      "refuseEventReservation",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: #00897f;
  color: white;
}
</style>
