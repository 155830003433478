<template>
  <div>
    <v-expansion-panels
      style="padding: 5px; justify-content: center; text-align: center;"
      multiple
      focusable
      popout
      v-model="selectedTabs"
    >
      <v-expansion-panel key="1">
        <v-expansion-panel-header class="head">
          Créer une Annonce
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form style="padding-top: 20px">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="create.title"
                  label="Titre de l'annonce"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  style="padding-top: 4px"
                  v-model="create.domain"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  label="Domaine d'intervention"
                  :items="domainsListe"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="create.department_id"
                  chips
                  clearable
                  deletable-chips
                  label="Département"
                  :items="departementsListe"
                  item-text="name"
                  item-value="id"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="create.desc"
                  label="Description"
                  counter
                  full-width
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <input
                  @change="handleImage"
                  class="custom-input"
                  type="file"
                  accept="image/*"
                />
                <img style="" :src="create.path_photo" alt="" />
              </v-col>
              <v-col cols="12" sm="12">
                <v-btn color="#00897F" text elevation="1" @click="Register"
                  >Sauvegarder</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="2">
        <v-expansion-panel-header class="head">
          Mes Annonces
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              v-for="annonce in announcement.announcements"
              :key="annonce.id"
              cols="12"
              md="6"
              sm="12"
            >
              <v-card elevation="2" class="overflow-y-auto">
                <div class="d-flex flex-no-wrap ">
                  <v-avatar class="ma-3" size="125" tile>
                    <v-img
                      v-if="annonce.file_name"
                      :src="
                        'https://api.just-form.fr/uploads/image/' +
                          annonce.file_name
                      "
                    ></v-img>
                  </v-avatar>
                  <div>
                    <v-card-title class="text-h5">
                      {{ annonce.title }}
                    </v-card-title>

                    <v-card-text>
                      {{ annonce.desc }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="red" @click="remove(annonce.id)">
                        Supprimer
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyAnnoucement",
  data: () => ({
    selectedTabs: [],
    create: {
      title: null,
      domain: null,
      department_id: null,
      desc: null,
      path_photo: "",
      mail: "",
      phone: "",
    },
  }),
  mounted() {
    this.fetchMyAnnouncementList();
  },
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("meta_data", { departementsListe: "getDepartements" }),
    ...mapGetters("announcement", { announcement: "getMyAnnouncementList" }),
  },
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.create.path_photo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    Register() {
      this.addAnnoucement(this.create);
    },
    remove(id) {
      this.deleteAnnoucement(id);
    },
    ...mapActions("announcement", [
      "addAnnoucement",
      "fetchMyAnnouncementList",
      "deleteAnnoucement",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: #00897f;
  color: white;
}

p {
  text-align: left;
}
</style>
