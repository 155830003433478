<template>
  <div :key="computedSelect">
    <v-row>
      <v-col cols="12" md="3" sm="12" style="justify-content: center;">
        <v-list outlined three-line>
          <v-list-item-group :value="selected" mandatory color="#00897F">
            <v-list-item
              v-for="(item, i) in filterItem"
              :key="i"
              :value="item.id"
            >
              <v-list-item-content style="text-align: center; ">
                <v-list-item-title
                  ><router-link
                    :to="{ name: 'Settings', params: { id: item.id } }"
                    class="menu-link"
                  >
                    <div class="main-color">
                      {{ item.name }}
                    </div>
                  </router-link></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" sm="12" md="9" v-if="selected === 0">
        <component :is="informationName" />
      </v-col>
      <v-col cols="12" sm="12" md="9" v-if="selected === 1">
        <component :is="calendarComponent" />
      </v-col>
      <v-col cols="12" sm="12" md="9" v-if="selected === 2">
        <p>
          <component :is="histoComponent" />
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="9" v-if="selected === 3">
        <AbonnementComponent :user="user" />
      </v-col>
      <v-col cols="12" sm="12" md="9" v-if="selected === 4">
        <MyAnnoucement />
      </v-col>
      <!-- https://recette-tpeweb.e-transactions.fr/php/  https://tpeweb.e-transactions.fr/php/-->

      <v-col cols="12" sm="12" md="9" v-if="selected === 5">
        <p>
          Annonces sauvegardées
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AbonnementComponent from "./common/Abonnement";
import MyAnnoucement from "./Annoucement/MyAnnoucement";

// Entreprise configuration EntrepriseHistorique
import EntrepriseInformation from "./EntrepriseConf/EntrepriseInformation";
import EntrepriseCalendar from "./EntrepriseConf/EntrepiseCalendar";
import EntrepriseHistorique from "./EntrepriseConf/EntrepriseHistorique";

// Organisme de Formation
import OrgFormInformation from "./OrganismeConf/OrgFormInformation";
import OrgFormCalendar from "./OrganismeConf/OrgFormCalendar";
import OrgFormHistorique from "./OrganismeConf/OrgFormHistorique";

// Formateur
import FormateurInformation from "./FormateurConf/FormateurInformation";
import FormateurCalendar from "./FormateurConf/FormateurCalendar";
import FormateurHistorique from "./FormateurConf/FormateurHistorique";

export default {
  name: "UserSetting",
  components: {
    EntrepriseInformation,
    OrgFormInformation,
    OrgFormCalendar,
    FormateurInformation,
    FormateurCalendar,
    EntrepriseCalendar,
    AbonnementComponent,
    OrgFormHistorique,
    FormateurHistorique,
    EntrepriseHistorique,
    MyAnnoucement,
  },
  mounted() {
    this.selected = this.$route.params.id;
    console.log("params", this.$route.params.id);
  },
  updated() {
    this.selected = this.$route.params.id;
  },
  data() {
    return {
      selected: 0,
      saveSelected: -1,
      add: false,
      dateMenu: false,
      date: null,
      debutMenu: false,
      finMenu: false,
      debutHeure: null,
      safe: -1,
      finHeure: null,
      items: [
        { id: 0, name: "Mes informations", profil: [0, 1, 2] },
        { id: 1, name: "Mon planning formation", profil: [0, 1, 2] },
        { id: 2, name: "Historique des formations", profil: [0, 1, 2] },
        { id: 4, name: "Petite annonces", profil: [0, 1, 2] },
        { id: 3, name: "Mon abonnement", profil: [0, 2] },
        { id: 5, name: "Annonces sauvegardées", profil: [-1] },
      ],
      profilListe: ["Formateur", "Entreprise", "OrgForm"],
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUser" }),
    filterItem() {
      let array = this.items.filter((element) => {
        if (element.profil.includes(this.user.type)) {
          return element;
        }
      });
      return array;
    },
    informationName() {
      return this.profilListe[this.user.type] + "Information";
    },
    calendarComponent() {
      return this.profilListe[this.user.type] + "Calendar";
    },
    histoComponent() {
      return this.profilListe[this.user.type] + "Historique";
    },
    computedSelect() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
.vdatetime-input {
  border: 1px solid #000;
}
</style>
