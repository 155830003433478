import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

import VueAxiosPlugin from "./plugins/vue-axios-plugin";

Vue.use(VueAxiosPlugin, {
  baseURL: "https://api.just-form.fr/api/",
  //baseURL: "http://127.0.0.1:8000/api/",
  timeout: 60000,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
