<template>
  <v-card elevation="2">
    <v-card-title
      style="text-align: center; justify-content: center; color: #00897F"
    >
      Suivis de votre abonnement</v-card-title
    >
    <v-card-text style="color: black">
      <div v-if="user.billed === 0" style="text-align: center">
        Actuellement vous n'êtes pas abonné.
        <form method="POST" action="https://tpeweb.e-transactions.fr/php/">
          <input type="hidden" name="PBX_SITE" :value="PBX_SITE" />
          <input type="hidden" name="PBX_RANG" :value="PBX_RANG" />
          <input
            type="hidden"
            name="PBX_IDENTIFIANT"
            :value="PBX_IDENTIFIANT"
          />
          <input type="hidden" name="PBX_SOURCE" :value="PBX_SOURCE" />
          <input type="hidden" name="PBX_TOTAL" :value="PBX_TOTAL" />
          <input type="hidden" name="PBX_DEVISE" :value="PBX_DEVISE" />
          <input type="hidden" name="PBX_CMD" :value="PBX_CMD" />
          <input type="hidden" name="PBX_PORTEUR" :value="PBX_PORTEUR" />
          <input type="hidden" name="PBX_REPONDRE_A" :value="PBX_REPONDRE_A" />
          <input type="hidden" name="PBX_RETOUR" :value="PBX_RETOUR" />
          <input type="hidden" name="PBX_HASH" value="SHA512" />
          <input type="hidden" name="PBX_TIME" :value="PBX_TIME" />
          <input type="hidden" name="PBX_HMAC" :value="PBX_HMAC" />
          <input type="submit" style="" value="M'abonner" />
        </form>
      </div>
      <div v-else>
        Votre abonnement à commencé le:
        <br />
        <span style="align-item: center">
          <v-btn dark color="red" @click="unsubscribe()">
            Mettre fin à l'abonnement
          </v-btn></span
        >
      </div>
    </v-card-text></v-card
  >
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Abonnement",
  props: {
    user: {
      required: false,
      default: null,
    },
  },
  mounted() {
    this.PBX_PORTEUR = this.user.email;
    if (this.user && this.user.billed === 0) {
      this.fetchPayement();
    }
  },
  data() {
    return {
      PBX_SITE: "2878101",
      PBX_RANG: "001",
      PBX_IDENTIFIANT: "38008629",
      PBX_SOURCE: "RWD",
      PBX_TOTAL: "",
      PBX_DEVISE: "978",
      PBX_CMD: "Ref_Cmd_010",
      PBX_PORTEUR: "",
      PBX_REPONDRE_A: "",
      PBX_RETOUR: "Mt:M;Ref:R;Auto:A;Erreur:E;Sign:K",
      PBX_HASH: "SHA512",
      PBX_TIME: "",
      PBX_HMAC: "",
    };
  },
  methods: {
    fetchPayement() {
      this.userPayInformation({
        type: this.user.type,
        email: this.user.email,
      }).then((response) => {
        this.PBX_CMD = response.data.pbx_cmd;
        this.PBX_HMAC = response.data.pbx_hmac;
        this.PBX_REPONDRE_A = response.data.pbx_repondre_a;
        this.PBX_TIME = response.data.pbx_time;
        this.PBX_TOTAL = response.data.pbx_total;
      });
    },
    unsubscribe() {
      this.unsubscribe()
        .then(() => {
          this.setSnack({
            text: "Votre demande de désabonnement à bien été envoyé.",
            color: "green",
          });
        })
        .catch(() => {
          this.setSnack({
            text:
              "Une erreur est survenu merci de ressayer ou de nous contacter",
            color: "red",
          });
        });
    },
    ...mapActions("user", ["userPayInformation", "userUnsubscribe"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>
