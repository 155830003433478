import Vue from "vue";
import VueRouter from "vue-router";
//import store from "@/store/index.js";

import Home from "../views/Home.vue";
import Login from "../views/Login";
import Register from "../views/Register";
import AccountSettings from "../views/AccountSettings";
import cdg from "../views/condition-general-utilisation";
import pdc from "../views/politique-confidentialite";
import contact from "../views/contact";
import formation from "../views/Formation";
import Node from "../views/Node";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      visible: false,
    },
  },
  {
    path: "/services",
    name: "services",
    component: Node,
    meta: {
      visible: true,
      isChildrenAncestor: true,
    },
    children: [
      {
        path: "catalog",
        name: "les-formations",
        component: formation,
        meta: {
          visible: true,
        },
      },
      {
        path: "former",
        name: "rechercher-un-formateur",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Search/SearchFormer.vue"
          ),
        meta: {
          requiresAuth: true,
          visible: true,
        },
      },
      {
        path: "training",
        name: "rechercher-une-formation",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Search/SearchTraining.vue"
          ),
        meta: {
          requiresAuth: true,
          visible: true,
        },
      },
      {
        path: "result-former",
        name: "result-former",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Search/ResultFormer.vue"
          ),
        props: true,
        meta: {
          visible: false,
        },
      },
      {
        path: "result-training",
        name: "result-training",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Search/ResultTraining.vue"
          ),
        props: true,
        meta: {
          visible: false,
        },
      },
      {
        path: "former/:id",
        name: "former-id",
        component: () => import("../views/Search/Detail/FormerDetail.vue"),
        meta: {
          requiresAuth: true,
          visible: false,
        },
      },
      {
        path: "training/:id",
        name: "training-id",
        component: () => import("../views/Search/Detail/TrainingDetail.vue"),
        meta: {
          requiresAuth: true,
          visible: false,
        },
      },
    ],
  },
  {
    path: "/offers/create",
    name: "create-annonces",
    component: () =>
      import(
        /* webpackChunkName: "home-sub-view-1" */ "../components/AccountSettings/Annoucement/MyAnnoucement.vue"
      ),
    meta: {
      visible: false,
    },
  },
  {
    path: "/offers",
    name: "petites-annonces",
    component: Node,
    meta: {
      isChildrenAncestor: true,
      visible: true,
    },
    children: [
      {
        path: "/offers/catalog",
        name: "rechercher-une-annonce",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Offers/CreateOffers.vue"
          ),
        meta: {
          visible: true,
        },
      },
      {
        path: "/offers/create",
        name: "Déposer-une-annonce",
        component: () =>
          import(
            /* webpackChunkName: "home-sub-view-1" */ "../views/Offers/Create.vue"
          ),
        meta: {
          requiresAuth: true,
          visible: true,
        },
      },
    ],
  },
  {
    path: "/offers/result",
    name: "petites-annonces-result",
    component: () =>
      import(
        /* webpackChunkName: "home-sub-view-1" */ "../views/Offers/Search/ResultOffers.vue"
      ),
    meta: {
      visible: false,
    },
  },
  {
    path: "/offers/details/:id",
    name: "detail-annonce",
    component: () =>
      import(
        /* webpackChunkName: "home-sub-view-1" */ "../views/Offers/Search/DetailOffer.vue"
      ),
    meta: {
      visible: false,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      visible: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      visible: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      visible: false,
      requiresUnAuth: true,
    },
  },
  {
    path: "/settings/:id",
    name: "Settings",
    component: AccountSettings,
    props: true,
    meta: {
      requiresAuth: true,
      visible: false,
    },
  },
  {
    path: "/condition-general",
    name: "Cdg",
    component: cdg,
    meta: {
      visible: false,
    },
  },
  {
    path: "/politque-confidentialite",
    name: "pdc",
    component: pdc,
    meta: {
      visible: false,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: contact,
    meta: {
      visible: false,
    },
  },
  {
    path: "/:notFound(.*)",
    component: Home,
    meta: {
      visible: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(function(to, from, next) {
  const token = localStorage.getItem("userToken");
  if (to.meta.requiresAuth && !token) {
    next("/login");
  } else if (to.meta.requiresUnAuth && token) {
    next("/settings");
  } else {
    next();
  }
});

export default router;
