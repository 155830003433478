<template>
  <div id="wrapper" class="clearfix">
    <section id="content">
      <div class="content-wrap">
        <div class="title">
          <h1 class="main-color">
            Vous pouvez nous contacter par mail à just-form@orange.fr
          </h1>
        </div>
      </div>
      <div class="content-wrap" v-if="false">
        <div class="title">
          <h1 class="main-color">
            Nous contacter
          </h1>
        </div>
        <v-container>
          <form
            id="contactForm"
            name="contactForm"
            class="row mb-0"
            method="post"
          >
            <div class="col-6 form-group">
              <v-text-field v-model="lastname" label="Nom"></v-text-field>
            </div>

            <div class="col-6 form-group">
              <v-text-field v-model="firstname" label="Prénom"></v-text-field>
            </div>

            <div class="col-12 form-group">
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
              ></v-text-field>
            </div>
            <div class="col-12 form-group">
              <v-text-field v-model="title" label="Titre"></v-text-field>
            </div>
            <div class="col-12 form-group">
              <v-textarea
                v-model="text"
                label="Message"
                counter
                full-width
              ></v-textarea>
            </div>

            <div class="col-4 form-group">
              <v-btn @click="sendContact" class="mr-4" color="#00897f">
                <span style="color: white">Envoyer </span></v-btn
              >
            </div>
          </form>
        </v-container>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      firstname: "",
      lastnam: "",
      email: null,
      text: "",
      title: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    sendContact() {
      console.log("I send the contact");
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: block;
  justify-content: center;
  align-items: center;
}
</style>
