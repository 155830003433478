<template>
  <!-- Document Wrapper
	============================================= -->
  <div style="marging-top: 5rem;">
    <!-- Content
		============================================= -->
    <section id="content">
      <div class="content-wrap py-0"></div>
    </section>
    <!-- #content end -->

    <!-- Footer
		============================================= -->
    <footer id="footer" class="dark border-width-1 border-f5 block-footer-1">
      <div class="container">
        <!-- Footer Widgets
				============================================= -->
        <div class="footer-widgets-wrap">
          <div class="row col-mb-50">
            <div class="col-sm-4">
              <div class="widget clearfix">
                <img
                  src="../../assets/Original-logo.svg"
                  alt="Image"
                  class="footer-logo"
                  height="25"
                />
              </div>

              <p>
                <strong>Just'Form</strong> est une entreprise française qui
                propose à la
                <strong>
                  communauté des professionnels de la santé sécurité au travail </strong
                >un service en ligne pour rendre
                <strong>visible et réserver</strong> des
                <strong>formations</strong> et des <strong>formateurs</strong>.
              </p>
            </div>

            <div class="col-sm-8">
              <div class="row gutter-50">
                <div class="col-lg-3 col-6">
                  <div class="widget widget_links clearfix">
                    <h3 class="nott ls0" style="color: #00897F">
                      Rechercher
                    </h3>

                    <ul>
                      <li>
                        <a href="/services/training">Une formation</a>
                      </li>
                      <li>
                        <a href="/services/former">Un formateur</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="widget widget_links clearfix">
                    <h3 class="nott ls0" style="color: #00897F">
                      Espaces utilisateurs
                    </h3>

                    <ul>
                      <li>
                        <a href="/">Espace formateur</a>
                      </li>
                      <li>
                        <a href="/">Espace Organisme de formation</a>
                      </li>
                      <li>
                        <a href="/">Espace Entreprise</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="widget widget_links clearfix">
                    <h3 class="nott ls0" style="color: #00897F">
                      Informations générales
                    </h3>

                    <ul>
                      <li>
                        <a href="/condition-general"
                          >Conditions générales d’utilisation</a
                        >
                      </li>
                      <li>
                        <a href="/politque-confidentialite"
                          >Politique de confidentialité</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-3 col-6">
                  <div class="widget widget_links clearfix">
                    <h3 class="nott ls0" style="color: #00897F">
                      Ressources
                    </h3>

                    <ul>
                      <li>
                        <a href="/contact">Nous contacter</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- .footer-widgets-wrap end -->
      </div>

      <!-- Copyrights
			============================================= -->
      <div id="copyrights" class="dark">
        <div class="container">
          <div class="row col-mb-30">
            <div class="col-md-4 text-center text-md-start">
              <div
                class="d-flex justify-content-center justify-content-md-start"
              >
                <a href="https://m.facebook.com/Just-form-112773341187414/" class="social-icon si-small si-colored si-facebook" target="_blank">
                  <i class="icon-facebook"></i>
                  <i class="icon-facebook"></i>
                </a>

                <a href="https://www.instagram.com/justform2021/" class="social-icon si-small si-colored si-instagram" target="_blank">
                  <i class="icon-instagram"></i>
                  <i class="icon-instagram"></i>
                </a>

                <a href="https://www.linkedin.com/in/just-form-just-form-809038228/" class="social-icon si-small si-colored si-linkedin" target="_blank">
                  <i class="icon-linkedin"></i>
                  <i class="icon-linkedin"></i>
                </a>
              </div>
            </div>

            <div class="col-md-4 text-center text-md-center">
              &copy; 2021 All Rights Reserved by Just’Form<br />
              Numéro de SIRET : 891 575 078 00011 - Raison sociale : Just’Form -
              Siège : rue des Charmilles 54290 GRIPPORT - Date de création de
              l’entreprise 30/12/2020
            </div>
          </div>
        </div>
      </div>
      <!-- #copyrights end -->
    </footer>
    <!-- #footer end -->
  </div>
  <!-- #wrapper end -->
</template>

<script>
export default {
  name: "MyFooter",
};
</script>
