<template>
  <v-card elevation="2">
    <v-card-title class="justify-center">
      Historique des formations
    </v-card-title>
    <v-card-text>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
      <v-row>
        <v-col
          v-for="(reservation, index) in history.events"
          :key="index"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card elevation="1" max-height="400px" class="overflow-y-auto">
            <v-card-title class="justify-center">
              Formation: {{ reservation.name }} <br />le
              {{ dateFromat(reservation.start) }}
              <br />
              Par {{ reservation.creator.contact.name }}
            </v-card-title>
            <v-card-text>
              Noter l'organisme de formation:
              <v-rating
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
                empty-icon="$ratingFull"
                half-increments
                hover
                large
              ></v-rating>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination
        @input="refreshHistory"
        v-model="page"
        class="my-4"
        :length="length"
        :total-visible="7"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as dayjs from "dayjs";

export default {
  name: "FormativeHistorique",
  mounted() {
    this.fechtHistory({ limit: 20, offset: 0 });
  },
  data: () => ({
    rating: 0,
    page: 1,
  }),
  computed: {
    length() {
      return Math.ceil(this.history.size_max / 20);
    },
    ...mapGetters("former", {
      history: "getHistory",
    }),
  },
  methods: {
    dateFromat(date) {
      return dayjs(date, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    refreshHistory() {
      this.fechtHistory({ limit: 20, offset: this.page - 1 });
    },
    ...mapActions("former", ["fechtHistory"]),
  },
};
</script>
