<template>
  <div>
    <v-container>
      <h4>Changer votre adresse email</h4>
      <EmailChange :email="emailChange.oldEmail" />
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <div class="col-12 form-group">
        <h4>Changer votre mot de passe</h4>
        <PasswordChange />
      </div>
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <h4>Changer votre profil</h4>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="lastname" label="Nom"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field v-model="firstname" label="Prénom"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field v-model="phone" label="Téléphone"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birth"
                label="Date de naissance"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birth"
              :active-picker.sync="activePicker"
              locale="FR-fr"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="departements"
            chips
            clearable
            deletable-chips
            multiple
            label="Selectionnez vos départements de mobilité"
            :items="dep"
            item-text="name"
            item-value="item"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
            v-model="description"
            label="Description"
            counter
            maxlength="500"
            full-width
          ></v-textarea>
        </v-col>

        <v-col sm="8"></v-col>
        <v-col sm="4">
          <v-btn type="button" depressed dark color="#00897f" @click="saveInfo">
            Valider
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <div style="padding-bottom: 10px">
        <h4 style="display: inline;">Domaine de formation</h4>
        <v-btn
          depressed
          dark
          color="#00897f"
          style="float: right;"
          @click="saveDomain"
        >
          Valider
        </v-btn>
      </div>
      <CompetenciesTable :competencies="competencies" />
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmailChange from "../common/EmailChange";
import PasswordChange from "../common/PasswordChange";
import CompetenciesTable from "../../Competencies/CompetencieTable";

export default {
  name: "FormateurInformation",
  components: { EmailChange, PasswordChange, CompetenciesTable },
  mounted() {
    this.competencies = this.profil.domain;
    this.emailChange = this.user.email;
    this.phone = this.user.phone;
    this.firstname = this.profil.first_name;
    this.lastname = this.profil.last_name;
    this.birth = this.profil.birthdate;
    this.description = this.profil.desc;
    this.departements = this.profil.department;
  },
  data() {
    return {
      emailChange: "",
      competencies: "",
      firstname: "",
      phone: "",
      lastname: "",
      birth: "",
      description: "",
      image: "",
      departements: [],
      agree: false,
      activePicker: null,
      date: null,
      menu: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUser", profil: "getProfil" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
  },
  methods: {
    changeMail() {},
    saveDomain() {
      let body = {
        domain: this.competencies,
      };
      this.changeMyDomain(body)
        .then((response) => {
          this.getUserById();
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error, color: "red" });
        });
    },
    saveInfo() {
      let body = {
        first_name: this.firstname,
        last_name: this.lastname,
        phone: this.phone,
        birthdate: this.birth,
        desc: this.description,
        department: this.departements,
      };
      this.changeMyInfo(body)
        .then((response) => {
          this.getUserById();
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error.message, color: "red" });
        });
    },
    ...mapActions("former", ["changeMyDomain", "changeMyInfo"]),
    ...mapActions("user", ["getUserById"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>
