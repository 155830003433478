<template>
  <div>
    <v-container>
      <h4>Changer votre adresse email</h4>
      <EmailChange :email="emailChange.oldEmail" />
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <div class="col-12 form-group">
        <h4>Changer votre mot de passe</h4>
        <PasswordChange />
      </div>
    </v-container>
    <v-divider></v-divider>

    <v-container>
      <h4>Changer votre profil</h4>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="companyName" label="Nom"></v-text-field
        ></v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="siret" label="Siret"></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="phone" label="Téléphone"></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="website" label="Site internet"></v-text-field
        ></v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="adress" label="Adresse Postale"></v-text-field
        ></v-col>
        <v-col cols="12" sm="12"
          ><v-select
            multiple
            v-model="selectedActivie"
            :items="activities"
            label="Secteur d'activité"
          ></v-select
        ></v-col>
        <div class="col-12 form-group">
          <v-textarea
            v-model="description"
            label="Description"
            counter
            maxlength="800"
            full-width
          ></v-textarea>
        </div>
        <v-col sm="4">
          <v-btn type="button" depressed dark color="#00897f" @click="saveInfo">
            Valider
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmailChange from "../common/EmailChange";
import PasswordChange from "../common/PasswordChange";

export default {
  name: "EntrepriseInformation",
  components: { EmailChange, PasswordChange },
  mounted() {
    this.companyName = this.profil.name;
    this.siret = this.profil.SIRET;
    this.emailChange = this.user.email;
    this.phone = this.user.phone;
    this.website = this.profil.site;
    this.adress = this.profil.address;
    this.description = this.profil.desc;
    this.selectedActivie = this.items;
  },
  data() {
    return {
      companyName: "",
      siret: "",
      emailChange: "",
      password: {
        oldPassword: "",
        newPassword: "",
        confNewPassword: "",
      },
      phone: "",
      website: "",
      adress: "",
      description: "",
      image: "",
      competencies: null,
      show1: false,
      show2: false,
      agree: false,
      selectedActivie: [],
      activities: [
        "Agroalimentaire",
        "Banque / Assurance",
        "Bois / Papier / Carton / Imprimerie",
        "BTP / Matériaux de construction",
        "Chimie / Parachimie",
        "Commerce / Négoce / Distribution",
        "Edition / Communication / Multimédia",
        "Electronique / Electricité",
        "Etudes et conseils",
        "Industrie pharmaceutique",
        "Informatique / Télécoms",
        "Machines et équipements / Automobile",
        "Métallurgie / Travail du métal",
        "Plastique / Caoutchouc",
        "Services aux entreprises",
        "Textile / Habillement / Chaussure",
        "Transports / Logistique",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  computed: {
    items() {
      let data = [];
      this.profil.sector.map((elem) => {
        data.push(elem.name);
      });
      return data;
    },
    ...mapGetters("user", { user: "getUser", profil: "getProfil" }),
  },
  methods: {
    changeMail() {},
    saveInfo() {
      let body = {
        name: this.companyName,
        phone: this.phone,
        address: this.adress,
        site: this.website,
        SIRET: this.siret,
        desc: this.description,
        sector: this.selectedActivie,
      };
      this.changeMyInfo(body)
        .then((response) => {
          this.getUserById();
          this.setSnack({ text: response.message });
        })
        .catch((error) => {
          this.setSnack({ text: error.message, color: "red" });
        });
    },
    ...mapActions("enterprise", ["changeMyInfo"]),
    ...mapActions("user", ["getUserById"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
};
</script>
