<template>
  <div class="bg-transparent">
    <!-- <v-carousel :show-arrows="false" style="fullwith">
      <v-carousel-item v-for="(item, i) in items" :key="i"> </v-carousel-item>
      ../assets/home-background.jpeg
    </v-carousel> -->

    <!-- Page Sub Menu
		============================================= -->
    <div
      v-if="!mobileView"
      id="page-menu"
      class="dots-menu dots-menu-border"
      style="padding-right: 3rem"
    >
      <div id="page-menu-wrap">
        <div class="container">
          <div class="page-menu-row">
            <div class="page-menu-title">Explorer <span>Just Form</span></div>

            <nav class="page-menu-nav">
              <ul class="page-menu-container one-page-menu" data-offset="0">
                <li class="page-menu-item">
                  <a @click="scrollToSection('slider')"><div>Accueil</div></a>
                </li>
                <li class="page-menu-item">
                  <a @click="scrollToSection('section-about')"
                    ><div>A propose de nous</div></a
                  >
                </li>
                <li class="page-menu-item">
                  <a @click="scrollToSection('section-formateur')"
                    ><div>Pour les formateur</div></a
                  >
                </li>
                <li class="page-menu-item">
                  <a @click="scrollToSection('section-entreprise')"
                    ><div>Pour les entreprises</div></a
                  >
                </li>
                <li class="page-menu-item">
                  <a @click="scrollToSection('section-org')"
                    ><div>Pour les organismes de formation</div></a
                  >
                </li>
                <li class="page-menu-item">
                  <a @click="scrollToSection('section-pricing')"
                    ><div>Tarifs</div></a
                  >
                </li>
              </ul>
            </nav>

            <div id="page-menu-trigger"><i class="icon-reorder"></i></div>
          </div>
        </div>
      </div>
    </div>
    <!-- #page-menu end -->

    <section
      ref="slider"
      id="slider"
      class="slider-element slider-parallax min-vh-60 min-vh-md-100 dark include-header"
    >
      <div
        class="slider-inner overlay"
        style="background-image:  url('https://just-form.fr/img/image-de-fond-Home.7af8c42a.svg'); background-position: center center;"
      >
        <div
          class="container slider-texte "
          id="slider-texte"
          ref="slider-texte"
          :style="{
            opacity: opacityTexteSlider,
          }"
        >
          <div class="slider-caption slider-caption-center">
            <h2 class="h1 fw-semibold mb-5 text-center">
              Just’Form
            </h2>
            <h3 style="padding-top: 50px; ">
              SITE DE RENCONTRE ENTRE PROFESSIONNELS DE LA SANTÉ SÉCURITÉ AU
              TRAVAIL
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section id="content" style="padding-bottom: 100px; ">
      <div class="content-wrap py-0">
        <section
          ref="section-about"
          id="section-about"
          class="page-section section bg-transparent m-0"
        >
          <div class="clearfix container">
            <div class=" heading-block bottommargin-lg center  ">
              <h1 class="main-color">POURQUOI UTILISER JUST’FORM ?</h1>
            </div>

            <v-row style="justify-content: center; padding-right: 10px;">
              <v-col sm="6" xs="12" style="padding-bottom: 4rem">
                <div>
                  <v-img
                    transition="slide-x-transition"
                    src="../assets/Homepage-SVG/HOMEPAGE-ill-Avantage1.svg"
                  >
                  </v-img>
                  <h3 class="text-center main-color">
                    Accéder aux
                    <strong>disponibilités de formateurs qualifiés </strong>
                    dans des dizaines de domaines
                  </h3>
                </div>
              </v-col>
              <v-col sm="6" xs="12" style="padding: 10px;">
                <div>
                  <v-img
                    transition="slide-x-reverse-transition"
                    src="../assets/Homepage-SVG/HOMEPAGE-ill-Avantage2.svg"
                  >
                  </v-img>
                  <h3 class="text-center main-color">
                    Réserver en ligne
                    <strong> 24h/24 et 7j/7 </strong> une formation ou un
                    formateur
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="justify-content: center; padding-right: 10px;"
            >
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center;"
              >
                <v-img
                  transition="slide-x-transition"
                  src="../assets/Homepage-SVG/imageRoleDeLorgDeForm.svg"
                >
                </v-img>
              </v-col>
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center;"
              >
                <div style="justify-content: center;">
                  <h3 class="text-justify">
                    RÔLE DE L'ORGANISME DE FORMATION
                  </h3>
                  <p class="text-justify main-text">
                    Il propose des formations destinées aux salariés ou futurs
                    salariés dans le domaine de la santé sécurité au travail
                    afin de permettre aux entreprises de maitriser leurs risques
                    professionnels. Les formations proposées s’adaptent aux
                    spécificités de chaque entreprise afin de répondre à la
                    réalité de terrain.
                  </p>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center;"
              >
                <div class="content">
                  <h3 class="text-justify" style="padding-top: 5rem">
                    RÔLE DU FORMATEUR
                  </h3>
                  <p class="text-justify main-text">
                    Il participe à la lutte contre les accidents du travail et
                    les maladies professionnelles en animant des formations dans
                    les domaines de la santé et sécurité.
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center;"
              >
                <v-img
                  transition="slide-x-reverse-transition"
                  src="../assets/Homepage-SVG/imageRoleDuFormateur.svg"
                >
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>

        <section
          ref="section-formateur"
          id="section-formateur"
          class="page-section section bg-transparent m-0"
        >
          <div class="clearfix container">
            <div class=" heading-block bottommargin-lg center  ">
              <h3 class="main-color">Formateurs</h3>
              <span class="main-text"
                >L'objectif de ce service est de permettre aux formateurs
                œuvrant dans le domaine de la prévention des risques
                professionnels d’améliorer l'efficacité de leur organisation,
                leur communication avec les organismes de formation et leur
                visibilité.</span
              >
            </div>
            <v-row
              align="center"
              style="justify-content: center; padding-right: 10px; "
            >
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center; "
              >
                <v-img
                  transition="slide-x-transition"
                  src="../assets/Homepage-SVG/Formateur.svg"
                >
                </v-img>
              </v-col>
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align: center; justify: center; vertical-align: middle;"
              >
                <div style="justify-content: center;    ">
                  <p class="texte-presentation">
                    Vos compétences sont mises en valeurs et visibles par les
                    organismes de formation.
                  </p>
                  <p class="texte-presentation">
                    En quelques clics, à n'importe quelle heure du jour ou de la
                    nuit, JUST’FORM vous permet d’être rapidement réservé pour
                    une formation dans vos domaines de compétences.
                  </p>
                  <p class="texte-presentation">
                    Vos disponibilités sont visibles dans un planning dont vous
                    avez la gestion.
                  </p>
                  <p class="texte-presentation">
                    Ce site vous permet également de vous soulager dans vos
                    démarches de démarchage auprès des organismes de formation.
                  </p>
                  <p class="texte-presentation">
                    Vous cherchez à vendre ou à acheter du matériel pédagogique
                    JUST’FORM vous permet de déposer ou consulter gratuitement
                    des annonces.
                  </p>
                  <p class="texte-presentation">
                    N .B : Le profil formateur s’adresse aux formateurs
                    indépendants
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </section>
        <section
          ref="section-entreprise"
          id="section-entreprise"
          class="page-section section bg-transparent m-0"
        >
          <div class="clearfix container">
            <div class=" heading-block bottommargin-lg center  ">
              <h3 class="main-color">Entreprises</h3>
              <span
                >L'objectif de ce service est de permettre aux entreprises de
                trouver des formations pour leurs salariés dans les domaines de
                la prévention afin de combattre les risques professionnels et
                d’améliorer l'efficacité de leur organisation et leur
                communication avec les acteurs de la formation.</span
              >
            </div>
            <v-row
              align="center"
              style="justify-content: center; padding-right: 10px; "
            >
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align: center; justify: center; vertical-align: middle;"
              >
                <div style="justify-content: center;    ">
                  <p class="text-justify texte-presentation">
                    Grâce à JUST’FORM vous pourrez trouver partout en France et
                    dans les DOM TOM des formations pour vos salariés dans un
                    grand nombre de domaines d’interventions de la santé
                    sécurité au travail.
                  </p>
                  <p class="texte-presentation">
                    Des formations proposées par des professionnels de la
                    prévention œuvrant pour la baisse de la sinistralité en
                    entreprise.
                  </p>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center; "
              >
                <v-img
                  transition="slide-x-transition"
                  src="../assets/Homepage-SVG/Entreprise.svg"
                >
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section
          ref="section-org"
          id="section-org"
          class="page-section section bg-transparent m-0"
        >
          <div class="clearfix container">
            <div class=" heading-block bottommargin-lg center  ">
              <h3 class="main-color">Organismes de formations</h3>
              <span
                >L'objectif de ce service est de permettre aux organismes de
                formation œuvrant dans les domaines de la santé sécurité au
                travail, d’améliorer l'efficacité de leur organisation, leur
                communication avec les formateurs et les entreprises ainsi que
                leur visibilité.</span
              >
            </div>
            <v-row
              align="center"
              style="justify-content: center; padding-right: 10px; "
            >
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align-item: center; "
              >
                <v-img
                  transition="slide-x-transition"
                  src="../assets/Homepage-SVG/Organisme.svg"
                >
                </v-img>
              </v-col>
              <v-col
                cols="12"
                md="6"
                xs="12"
                style="padding: 10px; align: center; justify: center; vertical-align: middle;"
              >
                <div style="justify-content: center;    ">
                  <p class="text-justify texte-presentation ">
                    En quelques clics, à n'importe quelle heure du jour ou de la
                    nuit, JUST’FORM permet de trouver rapidement un formateur
                    professionnel dans un grand nombre de domaines
                    d’interventions sur tout le territoire français (DOM TOM
                    compris).
                  </p>
                  <p class="texte-presentation">
                    Ce site vous permet également de soulager les secrétaires
                    mais aussi de pallier aux annulations de dernière minute.
                  </p>
                  <p class="texte-presentation">
                    Vous disposez d’un planning de formation dont vous avez la
                    gestion pour être visible par les entreprises qui peuvent
                    réserver des places de formations pour leurs salariés.
                  </p>
                  <p class="texte-presentation">
                    Vous pourrez déposer ou consulter des annonces pour acheter
                    ou vendre du matériel pédagogique.
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </section>
        <section
          ref="section-pricing"
          id="section-pricing"
          class="page-section section bg-transparent m-0"
        >
          <div class="clearfix container">
            <div class="heading-block center">
              <h2>Tarifs</h2>
              <span>Nous proposons différents profils.</span>
            </div>

            <div
              class="row pricing block-pricing-10 justify-content-center align-items-stretch"
            >
              <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                <div
                  class="pricing-box d-flex flex-column justify-content-between align-items-center h-100 rounded-5 pricing-simple px-4 py-0 overflow-visible price-border"
                >
                  <h3 class="pricing-title ls0 bg-white fw-normal">
                    Entreprise
                  </h3>
                  <div class="pricing-price fw-semibold font-secondary">
                    <span class="price-unit"></span>Gratuit
                  </div>
                  <div class="pricing-features">
                    <h5 class="mb-2 fw-semibold">Inclus:</h5>
                    <ul style="list-style-type: circle;">
                      <li>
                        <p>
                          La <strong> recherche et la réservation </strong> de
                          formations pour vos salariés dans un catalogue de plus
                          de 50 formations.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <router-link
                      :to="{ name: 'Settings', params: { id: 3 } }"
                      class="menu-link"
                    >
                      <div class="main-color">
                        Souscrire maintenant
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                <div
                  class="pricing-box d-flex flex-column justify-content-between align-items-center h-100 rounded-5 pricing-simple px-5 py-0 overflow-visible price-border"
                >
                  <h3 class="pricing-title ls0 bg-white fw-normal">
                    Formateur
                  </h3>
                  <div class="pricing-price fw-semibold font-secondary">
                    <span class="price-unit">&euro;</span>9.90<span
                      class="price-tenure font-body"
                    >
                      / mois</span
                    ><br />
                    <span class="price-tenure font-body">
                      engagement de 12 mois</span
                    >
                  </div>
                  <div class="pricing-features">
                    <h5 class="mb-2 fw-semibold">Inclus:</h5>
                    <ul style="list-style-type: circle;">
                      <li>
                        <p>
                          La <strong> recherche de formations </strong> dans un
                          catalogue de plus de
                          <strong> 50 formations </strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          Votre <strong> planning en ligne </strong> pour
                          réserver vos services selon vos disponibilités
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong> Consultation de votre profil </strong> par
                          les organismes de formation
                        </p>
                      </li>
                      <li><p>Gestion de votre planning</p></li>
                      <li>
                        <p>
                          La possibilité de
                          <strong>
                            passer des annonces de recherche ou de
                            vente/location
                          </strong>
                          de matériel pédagogique
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong> Consulter les annonces </strong> en ligne et
                          contacter l’annonceur
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <router-link
                      :to="{ name: 'Settings', params: { id: 3 } }"
                      class="menu-link"
                    >
                      <div class="main-color">
                        Souscrire maintenant
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
                <div
                  class="pricing-box d-flex flex-column justify-content-between align-items-center h-100 rounded-5 pricing-simple px-5 py-0 overflow-visible price-border"
                >
                  <h3 class="pricing-title ls0 bg-white fw-normal">
                    Organisme de formation
                  </h3>
                  <div class="pricing-price fw-semibold font-secondary">
                    <span class="price-unit">&euro;</span>15.00<span
                      class="price-tenure font-body"
                      >/ mois</span
                    ><br />
                    <span class="price-tenure font-body">
                      engagement de 12 mois</span
                    >
                  </div>
                  <div class="pricing-features">
                    <h5 class="mb-2 fw-semibold">Inclus:</h5>
                    <ul style="list-style-type: circle;">
                      <li>
                        <p>
                          La <strong> recherche de formations </strong> dans un
                          catalogue de plus de
                          <strong> 50 formations </strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          La recherche et la consultation de
                          <strong> formateurs </strong> pour
                          <strong> animer vos formations </strong> dans
                          <strong> des dizaines de domaines </strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          La mise en ligne de
                          <strong> vos formations </strong> et la possibilité
                          pour les entreprises de
                          <strong> réserver des places </strong>
                        </p>
                      </li>
                      <li><p>La Gestion de vos plannings de formation</p></li>
                      <li>
                        <p>
                          La possibilité de
                          <strong>
                            passer des annonces de recherche ou de
                            vente/location
                          </strong>
                          de matériel pédagogique
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong> Consulter les annonces </strong> en ligne et
                          contacter l’annonceur
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <router-link
                      :to="{ name: 'Settings', params: { id: 3 } }"
                      class="menu-link"
                    >
                      <div class="main-color">
                        Souscrire maintenant
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  data() {
    return {
      isActive: false,
      backgroundImage: `url(${require("@/assets/Homepage-SVG/image-de-fond-Home.svg")})`,
      opacityTexteSlider: 1,
      mobileView: true,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 1000;
    },
    scrollToSection(name) {
      let element = this.$refs[name];
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    fadeOutOnScroll(element) {
      if (!element) {
        return;
      }

      var distanceToTop =
        window.pageYOffset + element.getBoundingClientRect().top;
      var elementHeight = element.offsetHeight;
      var scrollTop = document.documentElement.scrollTop;

      var opacity = 1;

      if (scrollTop > distanceToTop) {
        opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
      }

      if (opacity >= 0) {
        element.style.opacity = opacity;
      }
    },
    scrollHandler() {
      this.opacityTexteSlider =
        1 - window.scrollY / (this.$refs.slider.clientHeight / 2);
    },
  },
};
</script>

<style scoped>
.price-border {
  border: 3px solid black;
}

.price-border:hover {
  border: 5px solid #00d8c9;
}

.overlay {
  position: fixed;
}

.slider-texte {
  z-index: 99;
}

.overlay:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  opacity: 1;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
}

.content {
  display: block;
  justify-content: center;
  align-items: center;
}

.texte-presentation {
  color: #00897f;
  font-size: 1.2rem;
}
.image-text {
  background-color: transparent;
  font-weight: bold;
  position: absolute;
  top: 50%;
  margin-left: 7rem;
  margin-right: 7rem;
}

.img-text {
  font-size: 5rem;
  display: inline;
  color: #00d8c9;
}
.img-text-w {
  font-size: 5rem;
  display: inline;
  color: white;
}
</style>
