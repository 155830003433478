<template>
  <header
    id="header"
    class="full-header sticky-header dark sticky-header-shrink"
  >
  
    <div id="header-wrap">
        
      <div class="container">
      
        <div class="header-row">
          <!-- Logo
						============================================= -->
          <div id="logo">
            <router-link
              :to="{ name: 'Home' }"
              class="standard-logo"
              data-dark-logo="images/logo-dark.png"
              ><img src="@/assets/Original-logo.svg" alt="Just'form"
            /></router-link>
            <router-link
              :to="{ name: 'Home' }"
              class="retina-logo"
              data-dark-logo="../../assets/Original-logo.svg"
              ><img src="../../assets/Original-logo.svg" alt="Just'form"
            /></router-link>
          </div>
          <!-- #logo end -->

          <div class="header-misc">
            <!-- Top Search
							============================================= -->
            <!--  <div id="top-search" class="header-misc-icon">
              <a href="#" id="top-search-trigger"
                ><i class="icon-line-search"></i><i class="icon-line-cross"></i
              ></a>
            </div> -->
            <!-- #top-search end -->

            <!-- Top Profile
							============================================= -->
            <div id="profile" class="header-misc-icon">
              <a v-if="!auth" href="/login" id="top-profile-trigger">
                <v-icon color="white">mdi-account</v-icon></a
              >
              <v-menu v-else open-on-hover offset-y close-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="white"
                    >mdi-account</v-icon
                  >
                </template>

                <v-list dark>
                  <v-list-item>
                    <router-link
                      class="menu-link"
                      :to="{ name: 'Settings', params: { id: 0 } }"
                    >
                      <div class="main-color">Paramètre</div>
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      exact-active-class="text-success"
                      :to="{ name: 'Settings', params: { id: 1 } }"
                      class="menu-link"
                    >
                      <div class="main-color">Mes formations</div>
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      exact-active-class="text-success"
                      :to="{ name: 'Settings', params: { id: 2 } }"
                      class="menu-link"
                    >
                      <div class="main-color">Mon historique</div>
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      exact-active-class="text-success"
                      :to="{ name: 'Settings', params: { id: 4 } }"
                      class="menu-link"
                    >
                      <div class="main-color">Mes annonces</div>
                    </router-link>
                  </v-list-item>
                  <v-list-item v-if="user.type !== 1">
                    <router-link
                      exact-active-class="text-success"
                      :to="{ name: 'Settings', params: { id: 3 } }"
                      class="menu-link"
                    >
                      <div class="main-color">Mon abonnement</div>
                    </router-link>
                  </v-list-item>

                  <v-list-item>
                    <v-btn text color="red" @click="logout">Déconnexion</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!-- #top-profile end -->
          </div>

          <!-- Primary Navigation
						============================================= -->
          <nav class="primary-menu">
            <ul class="menu-container">
              <MenuItem v-for="(r, i) in routes" :key="i" :route="r"></MenuItem>
            </ul>
          </nav>
          <!-- #primary-menu end -->
          <form class="top-search-form" action="search.html" method="get">
            <input
              type="text"
              name="q"
              class="form-control"
              value=""
              placeholder="Type &amp; Hit Enter.."
              autocomplete="off"
            />
          </form>
        </div>
      </div>
      <v-system-bar v-if="top  && annonce && annonce.active === 1"
      dark
      color="#00897F !important"
      height="30"
      lights-out
      elevation="3"
     style="display: flex; justify-content: center;"
    >
    <div class="text-annonce">
    {{annonce.sentence}}
    </div>
     </v-system-bar>
    </div>

  </header>
  <!-- #header end -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      showUser: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      listOne: false,
      listTwo: false,
      listThree: false,
      listFour: false,
      drawer: false,
      top: true,
    };
  },
   mounted() {
    window.addEventListener("scroll", this.scrollHandler);
    this.fetchAnnouncement();
    
   },
   destroyed() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  components: {
    MenuItem: () => import(/* webpackChunkName: "menu-item" */ "./MenuItem"),
  },
  computed: {
    screenW() {
      return screen.width;
    },
    routes() {
      return this.$router.options.routes;
    },
    ...mapGetters("user", { auth: "isAuthenticated", user: "getUser" }),
    ...mapGetters("meta_data", { annonce: "getAnnouncement" }),
  },
 
  methods: {
     scrollHandler() {
        if (window.scrollY > 30)
          this.top = false;
        else 
        this.top = true;
    },
    ...mapActions("user", ["logout"]),
    ...mapActions("meta_data", ["fetchAnnouncement"]),
  },
};
</script>

<style lang="scss" scoped>

.text-annonce{
   font-size: 1rem;
   opacity: 1;
   color: white;
   font-weight: bold;
}

.color-message{
  color: #00D8C9;
}

span:hover {
  text-decoration: underline;
}

.menu {
  font: 14px/1.5 "Open Sans", sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu a {
  display: flex;
  padding: 20px;
  color: #fff;
  text-decoration: none;
  justify-content: center;
}

.menu li {
  display: block;
  float: left;
  position: relative;
  color: #fff;
  min-width: 180px;
}

.menu li ul {
  position: absolute;
  left: 0;
  top: 61px;
  margin: 0;
  padding: 0;
}

.menu li ul li {
  background: #333;
  transition: background 0.2s;
}

.menu li ul li:hover {
  background: #444;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
