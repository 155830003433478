<template>
  <div>
    <h2 style="text-align: center; color: #00897F">
      Confirmer votre addresse e-mail
    </h2>
    <h4 style="text-align: center;">
      Merci de rentrer le code reçu par mail dans l'espace ci dessous pour
      finaliser la création de votre compte <br />Attention, le mail peut être
      situé dans vos spams
    </h4>
    <v-card class="mx-auto" max-width="500">
      <v-card-text style="padding-top: 5px">
        <v-text-field counter maxlength="6" v-model="code"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="isCodeValid"
          text
          color="teal accent-4"
          @click="sendValidation"
        >
          Valider
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="teal accent-4" @click="resendMail">
          Renvoyer un mail
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EmailConfirmation",
  data() {
    return {
      code: "",
    };
  },
  methods: {
    sendValidation() {
      this.emailValidation(this.code);
      this.$emit("refresh");
    },
    resendMail() {
      console.log(this.user.id);
      this.sendMail(this.user.id);
    },
    ...mapActions("user", ["emailValidation", "sendMail"]),
  },
  computed: {
    isCodeValid() {
      if (this.code && this.code.length === 6) {
        return false;
      }
      return true;
    },
    ...mapGetters("user", { user: "getUser" }),
  },
};
</script>
