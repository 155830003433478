<template>
  <div>
    <v-toolbar flat>
      <v-btn outlined class="mr-4" color="#00897f" @click="setToday">
        Aujourd'hui
      </v-btn>
      <v-btn fab text small color="#00897f" @click="prev">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn fab text small color="#00897f" @click="next">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-sheet height="600px">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :events="events"
        color="#00897f"
        type="week"
        :weekdays="weekdays"
        locale="fr-FR"
        @click:event="handleEventClicked"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "SearchDetailAgenda",
  props: {
    loading: {
      required: false,
      default: false,
    },
    readonly: {
      required: false,
      default: false,
    },
    onEventClicked: {
      required: false,
      default: () => {},
    },
    events: {
      required: true,
    },
  },
  data: () => ({
    slots: [],
    focus: "",
    weekdays: [1, 2, 3, 4, 5, 6, 0],
  }),
  mounted() {
    this.$refs.calendar.scrollToTime("07:00");
  },
  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = "";
    },
    handleEventClicked({ nativeEvent, event }) {
      if (!this.readonly) this.onEventClicked(event);

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style></style>
