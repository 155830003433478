import Vue from "vue";
import Vuex from "vuex";

import user from "@/store/modules/user";
import meta_data from "@/store/modules/meta_data";
import former from "@/store/modules/former";
import organism from "@/store/modules/organism";
import enterprise from "@/store/modules/enterprise";
import training from "./modules/training";
import snackbar from "@/store/modules/snackbar";
import event from "@/store/modules/event";
import announcement from "@/store/modules/announcement";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    meta_data,
    former,
    training,
    snackbar,
    organism,
    event,
    enterprise,
    announcement,
  },
});
