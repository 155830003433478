const state = {
  data: {
    text: null,
    timeout: 2000,
    active: false,
    color: "green",
  },
};

const getters = {
  getData: (state) => {
    return state.data;
  },
};

const mutations = {
  setSnack(state, payload) {
    state.data.active = true;
    state.data.text = payload.text;
    if (payload.color) {
      state.data.color = payload.color;
    } else {
      state.data.color = "green";
    }
  },
};

const actions = {
  setSnack({ commit }, config) {
    commit("setSnack", config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
