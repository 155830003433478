const state = {};

const getters = {};

const mutations = {};

const actions = {
  patchEvent(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/formative", {
          formative_id: data.formative_id,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
