const state = {
  agenda: null,
  enterprise: null,
  event: [],
  waitingEvent: [],
  profil: null,
  user: null,
  history: [],
};

const getters = {
  getUser: (state) => {
    return state.user;
  },
  getProfil: (state) => {
    return state.profil;
  },
  getOrganism: (state) => {
    return state.enterprise;
  },
  getMyEvent: (state) => {
    return state.event;
  },
  getMyWaitingEvent: (state) => {
    return state.waitingEvent;
  },
  getHistory: (state) => {
    return state.history;
  },
};

const mutations = {
  setEnterprise(state, data) {
    state.enterprise = data;
  },
  setProfil(state, data) {
    state.profil = data;
  },
  setUser(state, data) {
    state.user = data;
  },
  setEvent(state, data) {
    state.event = data;
  },
  setWaitingEvent(state, data) {
    state.waitingEvent = data;
  },
  deleteEventReservation(state, data) {
    state.waitingEvent.splice(data, 1);
  },
  cancelEventReservation(state, data) {
    state.waitingEvent.splice(data, 1);
  },
  setHistory(state, data) {
    state.history = data;
  },
};

const actions = {
  fetchEnterpriseById({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("enterprise/" + id + "/get")
        .then(function(response) {
          commit("setEnterprise", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  changeMyInfo(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("enterprise/change", data)
        .then(function(response) {
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error.data.result);
        });
    });
  },
  fetchEvent({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("reservation/accept")
        .then(function(response) {
          commit("setEvent", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchWaitingEvent({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("reservation/waiting")
        .then(function(response) {
          commit("setWaitingEvent", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteEventReservation(_, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/reservation/cancel", {
          user_id: data.user_id,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refuseEventReservation({ commit }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.event_id + "/reservation/refuse", {
          user_id: data.user_id,
        })
        .then((response) => {
          commit("cancelEventReservation", data.index);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fechtHistory({ commit }, conf) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get(
          "enterprise/myhisto?limit=" + conf.limit + "&offset=" + conf.offset
        )
        .then(function(response) {
          commit("setHistory", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
