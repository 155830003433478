<template>
  <div class="login">
    <div id="wrapper" class="clearfix">
      <section id="content">
        <div class="content-wrap">
          <div class="container clearfix">
            <v-row>
              <v-col sm="6">
                <v-img src="../assets/LoginPage/illustration-connexion.svg" />
              </v-col>
              <v-col class="login">
                <h2 class="main-color">Se connecter</h2>

                <v-form
                  class="mt-15"
                  ref="form_login"
                  v-model="form_login_valid"
                  @keyup.enter.native="
                    form_login_valid && !form_login_loading
                      ? submitLoginForm()
                      : {}
                  "
                >
                  <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    label="E-mail"
                  ></v-text-field>
                  <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    label="Mot de passe"
                    v-model="password"
                    class="input-group--focused"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  <div class="col-12 mb-0">
                    <button
                      type="button"
                      class="button button-3d button-main m-0"
                      :loading="form_login_loading"
                      :disabled="form_login_loading"
                      @click="submitLoginForm"
                      value=""
                    >
                      Se connecter
                    </button>
                    <a
                      href="#"
                      class="float-end main-color"
                      style="color: #00897f"
                      >Mot de passe oublié ?</a
                    >
                  </div>

                  <a href="/register" style="color: #00897f">
                    Ou créer votre compte</a
                  >
                </v-form>
              </v-col>
            </v-row>
          </div>
        </div>
      </section>
      <!-- #content end -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {},
  data: () => ({
    form_login_valid: false,
    form_login_loading: false,
    email: "",
    password: "",
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  methods: {
    goToPage: function(page) {
      this.$router.push({
        name: page,
        params: null,
      });
    },
    emailRule(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    submitLoginForm: function() {
      var self = this;

      if (self.$refs.form_login.validate()) {
        self.form_login_loading = true;

        var user_login = {
          email: this.email,
          password: this.password,
        };

        self
          .login(user_login)
          .then(() => {
            self.$router.push({
              name: "Settings",
              params: { id: 0 },
            });
          })
          .catch(function() {
            self.setSnack({
              text: "Identifiant ou mots de passe incorecte",
              color: "red",
            });
          })
          .finally(() => {
            self.form_login_loading = false;
          });
      } else {
        // ylorenz todo
        this.setSnack({
          text: "Merci de rentrer l'identifiant et mots de passe",
          color: "red",
        });
      }
    },
    ...mapActions("user", ["register", "save", "login", "logout"]), // map `this.save(user)` to `this.$store.dispatch('save', user)`
    ...mapActions("snackbar", ["setSnack"]),
  },
  computed: {
    app_version() {
      return this.$app_version;
    },
    app_env() {
      return this.$app_env;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.login {
  padding: 50px;
}
.container.login-image {
  @media (max-width: 767px) {
    height: 50vh;
  }
}

.login-column {
  padding: 0 45px !important;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: absolute;
    background-color: white;
    padding: 80px 60px !important;
  }

  // @media (max-width: 1024px) {
  //   padding: 30px !important;
  // }
  @media (max-width: 767px) {
    height: 50vh;
  }
}

.login .v-input {
  input,
  textarea {
    font-size: 14px;
  }

  &.v-text-field {
    .v-input__slot {
      min-height: auto !important;
      border-radius: 8px;
      padding: 0 18px !important;
      legend {
        margin-left: 8px;
      }
    }
    fieldset {
      border-width: 1px !important;
      border-color: #d8d8d8;
    }
  }

  label.v-label {
    font-weight: 500;
    color: #1a1919;
    padding: 0 5px;
    // background-color: white;
    font-family: "Sofia Pro SemiBold";
  }
}

.login .text-hint {
  color: #bebebe;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    color: #9e9e9e;
  }
}

.login .v-btn {
  text-transform: inherit !important;
  font-size: 13px;
  letter-spacing: 1px;
  background-color: green !important;
}
</style>
