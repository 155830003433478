<template>
  <header
    id="header"
    class="full-header sticky-header dark sticky-header-shrink"
  >
    <div id="header-wrap">
      <v-system-bar color="grey accent-4"></v-system-bar>

      <v-app-bar color="dark accent-4" dark>
        <v-app-bar-nav-icon
          style="padding-left: 2rem"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-spacer style="text-align: center" :to="{ name: 'Home' }"
          ><span>Just’Form</span>
        </v-spacer>
        <v-btn v-if="!auth" href="/login" icon style="padding-right: 2rem">
          <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn
          v-else
          @click.stop="account = !account"
          icon
          style="padding-right: 2rem"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer v-if="drawer" v-model="drawer" bottom temporary dark>
        <v-list nav>
          <v-list-item-group no-action>
            <v-list-item :to="{ name: 'Home' }">
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'les-formations' }">
              <v-list-item-title>Les formations</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'rechercher-un-formateur' }">
              <v-list-item-title>Rechercher un formateur</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'rechercher-une-formation' }">
              <v-list-item-title>Rechercher une formation</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'petites-annonces' }">
              <v-list-item-title>Petites annonces</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
        v-if="account"
        v-model="account"
        bottom
        temporary
        dark
      >
        <v-list nav>
          <v-list-item-group>
            <v-list-item :to="{ name: 'Settings', params: { id: 0 } }">
              <v-list-item-title>Paramètre</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'Settings', params: { id: 1 } }">
              <v-list-item-title>Mes formations</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'Settings', params: { id: 2 } }">
              <v-list-item-title>Mon historique</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'Settings', params: { id: 3 } }">
              <v-list-item-title>Mon abonnement</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Settings', params: { id: 4 } }">
              <v-list-item-title>Mes annonces</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout" color="red">
              <v-list-item-title>Déconnexion</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MobileNavBar",
  data: () => ({
    drawer: false,
    account: false,
  }),
  methods: {
    ...mapActions("user", ["logout"]),
  },
  computed: {
    ...mapGetters("user", { auth: "isAuthenticated", user: "getUser" }),
  },
};
</script>

<style lang="scss" scoped>
.list-nav {
  background: black;
}
.list-nav-text {
  color: white;
}
</style>
