<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" sm="12" xs="12">
        <v-text-field
          v-model="email"
          :rules="[rules.email]"
          label="Ancienne addresse E-mail"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12" xs="12">
        <v-text-field
          v-model="emailNew"
          :rules="[rules.email]"
          label="Nouvelle addresse E-mail"
        ></v-text-field
      ></v-col>
      <v-col cols="12" md="4" sm="12" xs="12">
        <v-text-field
          v-model="emailConf"
          :rules="[rules.email]"
          label="Confirmer la nouvelle addresse e-mail"
        ></v-text-field
      ></v-col>
      <v-col sm="8"></v-col>
      <v-col sm="4">
        <v-btn depressed dark color="#00897f">
          Valider
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "EmailChange",
  data() {
    return {
      emailConf: "",
      emailNew: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  props: {
    email: {
      required: true,
    },
  },
};
</script>
