<template>
  <div class="card mb-0">
    <div class="card-body" style="padding: 40px;">
      <h3>Inscription organisme de formation</h3>

      <form
        id="register-form"
        name="register-form"
        class="row mb-0"
        action="#"
        method="post"
      >
        <div class="col-12 form-group">
          <v-text-field v-model="companyName" label="Nom"></v-text-field>
        </div>

        <div class="col-6 form-group">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
          ></v-text-field>
        </div>

        <div class="col-6 form-group">
          <v-text-field
            v-model="emailConf"
            :rules="[rules.required, rules.email]"
            label="Confirmer l'e-mail"
          ></v-text-field>
        </div>

        <div class="col-4 form-group">
          <v-text-field v-model="phone" label="Téléphone"></v-text-field>
        </div>

        <div class="col-4 form-group">
          <v-text-field v-model="website" label="Site internet"></v-text-field>
        </div>

        <div class="col-4 form-group">
          <v-text-field v-model="adress" label="Adresse Postale"></v-text-field>
        </div>

        <div class="col-6 form-group">
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            label="Mot de passe"
            hint="Au moins 8 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="show1 = !show1"
          ></v-text-field>
        </div>
        <div class="col-6 form-group">
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            label="Confirmer le mot de passe"
            hint="Au moins 8 characters"
            v-model="confPassword"
            class="input-group--focused"
            @click:append="show2 = !show2"
          ></v-text-field>
        </div>
        <v-col cols="12" sm="6">
          <v-autocomplete
            v-model="departements"
            chips
            clearable
            deletable-chips
            multiple
            label="Selectionnez vos départements de mobilité"
            :items="dep"
            item-text="name"
            item-value="id"
            dense
          ></v-autocomplete>
        </v-col>
        <div class="col-12 form-group">
          <v-textarea
            v-model="description"
            label="Description"
            counter
            maxlength="800"
            full-width
          ></v-textarea>
        </div>
        <div class="col-12 form-group">
          <h4>Domaine de formation</h4>
          <competenciesTable :competencies="competencies" type="1" />
        </div>

        <div class="col-12 form-group">
          <v-checkbox v-model="qualiopi" label="Certifié Qualiopi">
          </v-checkbox>
        </div>

        <div class="col-12 form-group">
          <v-checkbox v-model="agree">
            <template v-slot:label>
              <div>
                En cochant cette case je reconnais avoir pris connaissance et
                accepte les
                <!--  <v-tooltip bottom>
                  <template v-slot:activator="{ on }"> -->
                <a target="_blank" href="/condition-general" @click.stop>
                  conditions Générales d'Utilisation du site
                </a>
                <!-- </template>
                  S'ouve dans un nouvel onglet
                </v-tooltip> -->
                ainsi que sa
                <!--  <v-tooltip bottom>
                  <template v-slot:activator="{ on }"> -->
                <a target="_blank" href="/politque-confidentialite" @click.stop>
                  Politique de Confidientialité.
                </a>
                <!-- </template>
                  S'ouve dans un nouvel onglet
                </v-tooltip> -->
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="col-12 form-group">
          <v-btn
            type="button"
            depressed
            @click="sendRegister"
            dark
            style="background-color: #00897f;"
          >
            S'inscrire
          </v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import competenciesTable from "../Competencies/CompetencieTable";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrganismeFormationRegisterForm",
  components: { competenciesTable },
  created() {
    this.competencies = this.domains;
  },
  data() {
    return {
      companyName: "",
      email: "",
      emailConf: "",
      phone: "",
      website: "",
      adress: "",
      description: "",
      image: "",
      competencies: null,
      password: "",
      confPassword: "",
      departements: [],
      qualiopi: false,
      show1: false,
      show2: false,
      agree: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    sendRegister() {
      if (this.agree === false) {
        this.setSnack({
          text:
            " Merci d'accepter les conditions générales d'utilisation et la politque de confidentialite",
          color: "red",
        });
        return;
      }
      var organisme = {
        identifiant: this.email,
        email: this.email,
        password: this.password,
        password_confirmation: this.confPassword,
        name: this.companyName,
        address: this.adress,
        phone: this.phone,
        site: this.website,
        domain: this.competencies,
        qualiopi: this.qualiopi,
        desc: this.description,
        department: this.departements,
      };
      this.registerOrganisme(organisme)
        .then((response) => {
          this.$router.push("/login");
          this.setSnack({ text: response.message });
        })
        .catch(() => {
          this.setSnack({
            text:
              " Merci de renseigner tous les champs du formulaire pour vous inscrire",
            color: "red",
          });
        });
    },
    ...mapActions("user", ["registerOrganisme"]),
    ...mapActions("snackbar", ["setSnack"]),
  },
  computed: {
    ...mapGetters("meta_data", { domains: "getDomains" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
  },
};
</script>
