const state = {
  trainingList: null,
  training: null,
};

const getters = {
  getTrainingList: (state) => {
    return state.trainingList;
  },
  getTraining: (state) => {
    return state.training;
  },
};

const mutations = {
  setTrainingList(state, data) {
    state.trainingList = data;
  },
  setTraining(state, data) {
    state.training = data;
  },
};

const actions = {
  fetchTraining({ commit }, search) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("event/search?" + search)
        .then(function(response) {
          commit("setTrainingList", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  fetchTrainingById({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .get("events/" + id)
        .then(function(response) {
          commit("setTraining", response.data.data);

          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  registerEvent({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      this._vm.$axios
        .patch("event/" + data.id + "/register", data.body)
        .then(function(response) {
          dispatch("fetchTrainingById", data.id);
          resolve(response.data.result);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
