<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title class="training-title-container">
        <span class="training-title" v-if="event.name && event.creator.contact">
          {{ event.name + " par " + event.creator.contact.name }}
        </span>
        <span v-else class="training-title">
          Période de disponibilité
        </span>
      </v-card-title>
      <v-card-text>
        <v-container class="training-detail-container">
          <div v-if="event.active">
            <div>
              <v-icon> mdi-calendar-range </v-icon><span> {{ dateFrom }}</span>
              <span> - </span>
              <span>{{ dateTo }}</span>
            </div>
            <br />
            <div>
              <v-icon> mdi-google-maps</v-icon><b> Adresse :</b>
              {{ event.address }}, {{ event.city }}
            </div>
            <br />
            <div>
              <span>
                <v-icon>mdi-account</v-icon> Place maximum:
                {{ event.nb_place_max }}
                | Place minimum:
                {{ event.nb_place_min }} | Place restante:
                {{ event.nb_place_rest }}</span
              >
              <span> </span>
            </div>
            <br />
            <div>
              <span>
                <v-icon>mdi-domain</v-icon> Domaine:
                {{ domainsListe[event.domain_id - 1].name }}
              </span>
            </div>

            <br />
            <div>
              <span>
                <v-icon>mdi-information</v-icon> Description:
                {{ event.desc }}</span
              >
            </div>
            <br />
          </div>
          <div v-else>
            <v-icon> mdi-calendar-range </v-icon><span> {{ dateFrom }}</span>
            <span> - </span>
            <span>{{ dateTo }}</span>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="onClose">
          Annuler
        </v-btn>
        <v-btn color="red" class="main-color" text @click="onEventDeleted">
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "DetailEventBookModal",
  props: {
    opened: {
      required: false,
      default: false,
    },
    event: {
      required: true,
    },
    onDelete: {
      required: true,
    },
    onClose: {
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    dateFrom() {
      return dayjs(this.event.start, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
    dateTo() {
      return dayjs(this.event.end, "YYYY--MM-DD HH:mm").format(
        "DD/MM/YYYY HH:mm"
      );
    },
  },
  methods: {
    onEventDeleted() {
      this.onDelete(this.event);
    },
  },
};
</script>

<style>
.training-title-container {
  background-color: #00897f;
}

.training-title {
  font-weight: bold;
  font-size: 24px;
  color: white;
}

.training-detail-container {
  padding: 32px 16px 0 16px;
}

.training-date-wrapper {
  align-items: center;
}

.training-date-content {
  display: flex;
  flex-direction: column;
}
</style>
